// @flow

import style from "./style.module.scss";

import React from "react";
import classnames from "classnames";
import { Typography, Icon } from "@2po-dgp/components";
import { Container } from "reactstrap";
/**
 * HelpSection
 */
const HelpSection = ({ open, closeHelpSection }: *) => {
    const releaseNotes = [
        {
            title: "November 2021",
            text: (
                <ul>
                    <li>You can now create templates of your chosen files</li>
                    <li>You can archive salespacks from your history</li>
                    <li>There is a new layout for search results</li>
                </ul>
            ),
        },
    ];
    return (
        <div
            className={classnames(style.historyOverview, {
                [style.open]: open,
            })}
        >
            <Container>
                <div className={style.backButton} onClick={closeHelpSection}>
                    <Typography
                        type="panelHeaderText"
                        additionalClass={style.backButtonText}
                    >
                        <Icon
                            iconName={"listCarret"}
                            additionalClass={style.icon}
                            size="sm"
                        />
                        {"Back to overview"}
                    </Typography>
                </div>
                <div className={style.helpSection}>
                    <Typography type="panelHeader" color={"primary"}>
                        Release Notes
                    </Typography>
                    {releaseNotes.map((note, index) => {
                        return (
                            <div key={index}>
                                <Typography
                                    type="tableLabel"
                                    color={"secondary"}
                                >
                                    {note.title}
                                </Typography>
                                <div>
                                    <Typography type="new-bodyCopySmall">
                                        {note.text}
                                    </Typography>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={style.helpSection}>
                    <Typography type="panelHeader" color={"primary"}>
                        Where to go for help?
                    </Typography>
                </div>
                <div className={style.helpSection}>
                    <Typography type="panelHeader" color={"primary"}>
                        Content issues:
                    </Typography>
                    <div>
                        <Typography type="new-bodyCopySmall">
                            All remarks or requests regarding content can be
                            directed to{" "}
                            <a href="mailto:saleshub@degroofpetercam.com">
                                saleshub@degroofpetercam.com
                            </a>
                        </Typography>
                    </div>
                </div>
                <div className={style.helpSection}>
                    <Typography type="panelHeader" color={"primary"}>
                        Connection / technical issues:
                    </Typography>
                    <div>
                        <Typography type="new-bodyCopySmall">
                            If you have technical issues please contact the
                            service center on the following number: + 32 2 287
                            91 23
                        </Typography>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HelpSection;
