// @flow

import style from "./style.module.scss";

import React from "react";
import {
    AssetTileSimple,
    AssetTileSimpleLoading,
    Typography,
    Link,
    Icon,
} from "@2po-dgp/components";
import classnames from "classnames";
import { isArrayWithContent } from "@utils/Array";

const emptyString =
    "Your basket is empty. Please add documents via the dashboard.";

const Content = ({ items, className, onSelect, uploads }: *) => {
    return (
        <div className={style.content}>
            {isArrayWithContent(items) || isArrayWithContent(uploads) ? (
                <div className={classnames(className, style.bodyRow)}>
                    {isArrayWithContent(items) &&
                        items.map((item, index) => (
                            <AssetTileSimple
                                className={style.card}
                                key={`${item.id}-${index}`}
                                category={item.category}
                                title={item.title}
                                selected={item.selected}
                                onSelect={() => onSelect(item.id)}
                            />
                        ))}
                    {isArrayWithContent(uploads) &&
                        uploads.map(upload => (
                            <AssetTileSimpleLoading
                                className={style.card}
                                key={upload.id}
                                {...upload}
                            />
                        ))}
                </div>
            ) : (
                <Typography
                    type="emptyString"
                    additionalClass={style.emptyString}
                >
                    {emptyString}
                </Typography>
            )}
            <Link
                type="toolbarLink"
                icon={<Icon iconName="open" variant="primary" />}
                className={classnames(className, style.fundLink)}
                destination={
                    "https://www.dpamfunds.com/funds.html?country=BE&skipdisclaimer=true"
                }
                external
            >
                {"Fund factsheets"}
            </Link>
        </div>
    );
};

export default Content;
