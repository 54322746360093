import { useState, useEffect } from "react";
import { appendAssetFilter } from "@utils/Filter";
import getClient from "@utils/Client";
import { filterMissingAssets } from "@utils/Asset";
import { LANGUAGES } from "@utils/Constants";
// @flow

// use this for searching assets
const contentfulAssets = ({ limit, skip, query, filterValues, language }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const fullLanguageName = LANGUAGES.filter(
        LANG => LANG.key.toLowerCase() === language?.toLowerCase(),
    )[0]?.value;

    const client = getClient();
    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        skip: skip,
        limit: limit,
        query: query,
        order: "fields.title",
        "fields.language": fullLanguageName,
        "fields.excludeFromSearch": false,
        "fields.lastProcessed[exists]": true,
    };

    const getAssets = async () => {
        try {
            setLoading(true);
            const filters = appendAssetFilter(queryOptions, filterValues);
            const response = await client.getEntries(filters);
            const { items, limit, skip, total } = response;

            setData({
                items: filterMissingAssets(items),
                limit,
                skip,
                total,
            });
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [skip, limit, query, filterValues, language]);

    return { loading, data, error, query };
};
export default contentfulAssets;
