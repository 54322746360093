// @flow

import style from "./style.module.scss";

import React, { useEffect, useState } from "react";
import {
    InputField,
    Textarea,
    LanguageSelectorPopup,
    Button,
    Typography,
} from "@2po-dgp/components";
import useApi from "@hooks/useApi";
import { buildAssetsFromItems } from "@utils/BuildAssets";

type Props = {
    selectedItems: *,
    showSaveTemplatePopup: boolean,
    closeSaveTemplate: *,
    chosenLanguage?: string,
};

const SaveTemplate = ({
    selectedItems,
    showSaveTemplatePopup,
    closeSaveTemplate,
    chosenLanguage,
}: Props) => {
    const [templateName, setTemplateName] = useState("");
    const [templateDescription, setTemplateDescription] = useState("");
    const [templateBody, setTemplateBody] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    const saveTemplateToApi = () => {
        const apiBody = {
            templateName,
            templateDescription,
            assets: buildAssetsFromItems(selectedItems),
            chosenLanguage,
        };

        setTemplateBody(apiBody);
    };

    const { apiLoading, apiData, apiError } = useApi({
        url: "/backend/templates",
        method: "post",
        data: templateBody,
    });

    useEffect(() => {
        if (templateBody) {
            if (apiError) {
                console.log("### error occured creating template", apiError);
                setTemplateBody(null);
            }

            if (apiData?.templateId) {
                setTemplateName("");
                setTemplateDescription("");
                closeSaveTemplate(true);
            }
        }
    }, [apiData, apiError]);

    useEffect(() => {
        if (templateName !== "" && templateDescription !== "") {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }, [templateName, templateDescription]);

    return (
        <div>
            {showSaveTemplatePopup && (
                <LanguageSelectorPopup
                    title="Add a name and description for the template"
                    content={
                        <div className={style.templateInfo}>
                            <Typography type="bodyCopy">
                                The template will be saved in the language
                                chosen on the main dashboard
                            </Typography>
                            <InputField
                                label="Name"
                                labelType="inside"
                                className={style.input}
                                value={templateName}
                                onChange={val => setTemplateName(val)}
                            />
                            <Textarea
                                placeholder="Description"
                                onChange={val => setTemplateDescription(val)}
                                value={templateDescription}
                            />
                            <div className={style.actions}>
                                <Button
                                    transparent
                                    onClick={() => closeSaveTemplate()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={style.submit}
                                    disabled={!isFormValid}
                                    loading={apiLoading}
                                    icon="arrowRight"
                                    onClick={() => saveTemplateToApi()}
                                >
                                    Save Template
                                </Button>
                            </div>
                        </div>
                    }
                    onClose={() => closeSaveTemplate()}
                />
            )}
        </div>
    );
};

export default SaveTemplate;
