import { useState, useEffect } from "react";
import { appendAssetFilter } from "@utils/Filter";
import getClient from "@utils/Client";
import { LANGUAGES } from "@utils/Constants";
// @flow

// use this for getting the total amount of assets
const contentfulFilterCount = ({ filterValues, query, language }) => {
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(null);
    const [error, setError] = useState(null);

    const client = getClient();

    const fullLanguageName = LANGUAGES.filter(
        LANG => LANG.key.toLowerCase() === language.toLowerCase(),
    )[0].value;

    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        skip: 0,
        limit: 0,
        query: query,
        "fields.lastProcessed[exists]": true,
        "fields.language": fullLanguageName,
    };

    const getAssets = async () => {
        try {
            setLoading(true);
            const response = await client.getEntries(
                appendAssetFilter(queryOptions, filterValues),
            );
            const { total } = response;
            setTotal(total);
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [filterValues, query, language]);

    return { loading, total, error };
};
export default contentfulFilterCount;
