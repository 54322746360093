// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import { Loading, Icon, Typography } from "@2po-dgp/components";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import classnames from "classnames";

type Props = {
    file: string,
    className?: string,
};

/**
 * PDFPreview
 */
const PDFPreview = ({ file, className }: Props) => {
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleNextPage = () => {
        if (pageNumber === numPages) {
            setPageNumber(1);
        } else {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePreviousPage = () => {
        if (pageNumber === 1) {
            setPageNumber(numPages);
        } else {
            setPageNumber(pageNumber - 1);
        }
    };

    return (
        <Document
            className={classnames(className, style.document)}
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Loading className={style.loading} loading />}
        >
            <DocPage pageNumber={pageNumber} />
            {numPages > 1 && (
                <>
                    <Typography additionalClass={style.pagination}>
                        {pageNumber} / {numPages}
                    </Typography>
                    <Icon
                        iconName="arrow"
                        additionalClass={classnames(
                            style.control,
                            style.previous,
                        )}
                        onClick={() => handlePreviousPage()}
                        variant="primary"
                    />
                    <Icon
                        iconName="arrow"
                        additionalClass={classnames(style.control, style.next)}
                        onClick={() => handleNextPage()}
                        variant="primary"
                    />
                </>
            )}
        </Document>
    );
};

const DocPage = ({ pageNumber }: *) => {
    return (
        <>
            <Page
                className={classnames("d-none d-xl-block", style.page)}
                pageNumber={pageNumber}
                width={950}
            />
            <Page
                className={classnames(
                    "d-none d-lg-block d-xl-none",
                    style.page,
                )}
                pageNumber={pageNumber}
                width={750}
            />
            <Page
                className={classnames(
                    "d-none d-md-block d-lg-none",
                    style.page,
                )}
                pageNumber={pageNumber}
                width={600}
            />
            <Page
                className={classnames(
                    "d-none d-sm-block d-md-none",
                    style.page,
                )}
                pageNumber={pageNumber}
                width={430}
            />
            <Page
                className={classnames("d-sm-none", style.page)}
                pageNumber={pageNumber}
                width={200}
            />
        </>
    );
};

export default PDFPreview;
