import * as azureSDK from "@azure/storage-file-share";
import axios from "axios";

export const uploadToFileShare = async (file, fileName) => {
    const {
        token,
        directory,
        convertedfileName,
        url,
    } = await getSecureCredentials(fileName);

    const directoryClient = getDirectoryClient(token, directory, url);

    try {
        const userDirectoryExists = await directoryClient.exists();
        if (!userDirectoryExists) {
            await directoryClient.create();
        }

        const fileClient = directoryClient.getFileClient(convertedfileName);

        await fileClient.uploadData(file);

        return convertedfileName;
    } catch (error) {
        console.log("### error  uploading file", error);
    }
};

export const deleteFromFileShare = async fileName => {
    try {
        const { token, directory, url } = await getSecureCredentials();

        const directoryClient = getDirectoryClient(token, directory, url);
        const fileClient = directoryClient.getFileClient(fileName);

        await fileClient.deleteIfExists(fileName);
    } catch (error) {
        console.log("### error  deleting file", error);
    }
};

const getSecureCredentials = async fileName => {
    try {
        const tokenResponse = await axios({
            method: "GET",
            url: "/backend/asset/documentToken",
            params: {
                fileName,
            },
        });
        return tokenResponse.data;
    } catch (error) {
        console.log("### error", error);
    }
};

const getDirectoryClient = (token, directory, url) => {
    const serviceClient = new azureSDK.ShareServiceClient(
        `https://${url}?${token}`,
    );

    const directoryClient = serviceClient
        .getShareClient(directory)
        .getDirectoryClient("");

    return directoryClient;
};
