// @flow
import { LANGUAGES } from "@utils/Constants";

const getExtension = fileName => {
    const extension = fileName.slice(
        (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1,
    );
    return extension && extension !== "" ? `.${extension}` : "";
};

export const generateNormalizedFileName = (
    fileName: string,
    language?: string,
) => {
    // Get everything behind the . at the end for the extension
    const extension = getExtension(fileName);
    // Remove the extension from the filename, to attach it later on.
    let normalizedName = fileName
        .replace(extension, "")
        .split(" ")
        .join("_");
    normalizedName = normalizedName.split("-").join("_");
    normalizedName = normalizedName.split("é").join("e");
    normalizedName = normalizedName.split("è").join("e");
    normalizedName = normalizedName.split("ê").join("e");
    normalizedName = normalizedName.split("ë").join("e");
    normalizedName = normalizedName.split("à").join("a");
    normalizedName = normalizedName.split("á").join("a");
    normalizedName = normalizedName.split("â").join("a");
    normalizedName = normalizedName.split("ç").join("c");
    normalizedName = normalizedName.split("ñ").join("n");
    normalizedName = normalizedName.split("ò").join("o");
    normalizedName = normalizedName.split("ó").join("o");
    normalizedName = normalizedName.split("ô").join("o");
    normalizedName = normalizedName.split("ö").join("o");
    normalizedName = normalizedName.split("ù").join("u");
    normalizedName = normalizedName.split("ú").join("u");
    normalizedName = normalizedName.split("û").join("u");
    normalizedName = normalizedName.split("ü").join("u");
    if (language) {
        normalizedName = `${normalizedName}_${getIsoLanguage(language)}`;
    }
    // removes all special characters
    normalizedName = normalizedName.replace(/[^\w\s]/gi, "") + extension;

    return normalizedName;
};

export const getIsoLanguage = (language: string) => {
    return (
        LANGUAGES.find(lang => lang.value === language)?.key ||
        LANGUAGES[0]?.key ||
        "en"
    );
};
