// @flow

import style from "./style.module.scss";

import React from "react";
import { Typography } from "@2po-dgp/components";
import classnames from "classnames";

const Header = ({ title, copy, className }: *) => (
    <div className={classnames(style.content, className)}>
        <Typography type="drawerTitle" additionalClass={style.title}>
            {title}
        </Typography>
        <Typography type="successCopy" additionalClass={style.copy}>
            {copy}
        </Typography>
    </div>
);

export default Header;
