// @flow

import style from "./style.module.scss";

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
    Typography,
    InputField,
    Button,
    Selectbox,
    Checkbox,
    Popup,
} from "@2po-dgp/components";
import { DateField } from "@components";
import { schema } from "@utils/validation/createSalespack";
import { createSalesPackBody, getSalespackObject } from "@utils/SalesPack";
import {
    saveLocalStorage as save,
    loadLocalStorage as load,
} from "@utils/Storage";
import {
    SEGMENTS,
    SEGMENT_NAMES,
    LANGUAGES,
    STORAGE_SALESPACK_CONFIG,
    LANGUAGE_DROPDOWN_DEFAULT,
    SEGMENT_DROPDOWN_DEFAULT,
    DATEFIELD_DEFAULT,
    DRAWER_OPENED,
} from "@utils/Constants";

type Props = {
    onSubmit: *,
    className?: string,
    loading?: boolean,
    selectedItems: *,
};

const Content = ({ onSubmit, className, loading, selectedItems }: Props) => {
    const salespackConfig = load(STORAGE_SALESPACK_CONFIG);
    const [showPopup, setShowPopup] = useState(false);
    const [initials, setInitials] = useState(
        (salespackConfig?.initials: *) || "",
    );
    const [segment, setSegment] = useState((salespackConfig?.segment: *) || "");
    const [preferenceLanguage, setPreferenceLanguage] = useState(
        (salespackConfig?.preferenceLanguage: *) || "",
    );
    const [date, setDate] = useState(
        salespackConfig?.date ? new Date(salespackConfig.date) : undefined,
    );
    const [confirmed, setConfirmed] = useState(
        (salespackConfig?.confirmed: *) || false,
    );
    const [disabled, setDisabled] = useState(true);
    const [buttonPressed, setButtonPressed] = useState(false);
    const [errors, setErrors] = useState([]);
    const [showErrors, setShowErrors] = useState(false);

    const segments = SEGMENTS.concat();
    segments.unshift(SEGMENT_DROPDOWN_DEFAULT);
    const languages = LANGUAGES.concat();
    languages.unshift({
        key: LANGUAGE_DROPDOWN_DEFAULT,
        value: LANGUAGE_DROPDOWN_DEFAULT,
    });

    useEffect(() => {
        if (buttonPressed && confirmed) {
            onSubmit(
                getSalespackObject(
                    initials,
                    segment,
                    preferenceLanguage,
                    date,
                    confirmed,
                ),
            );
            setButtonPressed(false);
            save(DRAWER_OPENED, false);
        }
    }, [buttonPressed]);

    useEffect(() => {
        validate();
        save(
            STORAGE_SALESPACK_CONFIG,
            getSalespackObject(
                initials,
                segment,
                preferenceLanguage,
                date,
                confirmed,
            ),
        );
    }, [initials, segment, preferenceLanguage, date, confirmed]);

    useEffect(() => {
        if (salespackConfig) {
            if (Object.keys(salespackConfig).length === 0) {
                setInitials("");
                setSegment("");
                setPreferenceLanguage("");
                setDate("");
                setConfirmed(false);
            }
        }
    }, [salespackConfig]);

    const validate = () => {
        const salespack = createSalesPackBody(
            initials,
            segment,
            preferenceLanguage,
            date ? date : "",
            selectedItems,
            confirmed,
        );
        validateObject(salespack);
    };

    const validateObject = parameters => {
        schema
            .validate(parameters, {
                abortEarly: false,
                strict: true,
            })
            .then((result, errors) => {
                if (!errors) {
                    setDisabled(false);
                    setErrors([]);
                }
            })
            .catch(error => {
                setErrors(error.inner);
                setButtonPressed(false);
                setDisabled(true);
                setShowPopup(true);
            });
    };

    const removeError = id => {
        const tempArray = errors.concat();
        const index = tempArray.indexOf(errors.find(err => err.path === id));
        if (index > -1) {
            tempArray.splice(index, 1);
        }
        setErrors(tempArray);
    };

    const findError = id => errors.find(err => err.path === id);

    return (
        <div className={classnames(className, style.bodyRow)}>
            <div className={style.body}>
                <InputField
                    className={style.initials}
                    value={initials}
                    type={"text"}
                    name={"initials"}
                    placeholder="Your contact (e.g. ABCD)"
                    onChange={value => {
                        if (value?.length < 5) {
                            setInitials(value.toUpperCase());
                        }
                    }}
                    onBlur={() => removeError("initials")}
                    error={
                        (showErrors || initials?.length > 0) &&
                        findError("initials")?.message
                    }
                    hasError={
                        (showErrors || initials?.length > 0) &&
                        findError("initials")
                    }
                />
                <Typography
                    type="bodyCopy"
                    color="neutral"
                    additionalClass={style.disclaimer}
                >
                    {"Max. 4 characters"}
                </Typography>
                <Selectbox
                    placeholder="Select a preference language"
                    additionalClass={classnames("w-100", style.segments)}
                    value={preferenceLanguage}
                    onChange={value => {
                        setPreferenceLanguage(value);
                    }}
                    items={languages.map(language => ({
                        label: language.value,
                        value: language.key,
                    }))}
                    error={
                        showErrors && findError("preference-language")?.message
                    }
                />
                <Selectbox
                    placeholder="Select contact's segment"
                    additionalClass={classnames("w-100", style.segments)}
                    value={segment}
                    onChange={value => {
                        setSegment(value);
                    }}
                    items={segments.map(segment => ({
                        label: SEGMENT_NAMES[segment],
                        value: segment,
                    }))}
                    error={showErrors && findError("segment")?.message}
                />
                <div className={style.dateField}>
                    <DateField
                        className={"w-100"}
                        selected={date}
                        onChange={value => {
                            setDate(value);
                        }}
                        error={
                            showErrors && findError("appointment-date")?.message
                        }
                        placeholderText={DATEFIELD_DEFAULT}
                    />
                </div>
            </div>
            <div className={style.bottomRow}>
                {showErrors && showPopup && findError("confirmation") && (
                    <Popup
                        className={style.popup}
                        icon="infoCircle"
                        copy={findError("confirmation")?.message}
                        onClose={() => setShowPopup(false)}
                    />
                )}
                <Checkbox
                    onChange={e => {
                        setConfirmed(e);
                    }}
                    selected={confirmed}
                    className={style.checkbox}
                >
                    {"I confirm all data shared is compliant and anonimized"}
                </Checkbox>
                <Button
                    onClick={() => {
                        !disabled && setShowErrors(true);
                        !disabled && setButtonPressed(true);
                    }}
                    className={style.SVButton}
                    loading={loading}
                    disabled={selectedItems?.length === 0 ? true : disabled}
                    icon={"link"}
                    cover
                    iconSize="md"
                >
                    Generate link
                </Button>
            </div>
        </div>
    );
};

export default Content;
