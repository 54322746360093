// @flow

import moment from "moment";
import fileDownload from "js-file-download";
import axios from "axios";

/**
 * Fetch the basename from an url
 */
const basename = url =>
    url
        .trim()
        .split("?")[0]
        .split("/")
        .pop();

/**
 * Downloads a file from a gettable url as an attachment.
 *
 * Returns a boolean to indicate the success of the operation
 */
export const downloader = async (url: string, filename: string) => {
    const name = filename || basename(url);

    try {
        axios
            .get(url, {
                responseType: "blob",
            })
            .then(res => {
                fileDownload(res.data, name);
            });

        return true;
    } catch {
        return false;
    }
};

/**
 * Expands a filename into a basename and an extension
 */
export const expand = (filename: string) => {
    const splitted = filename.split(".");
    const extension = splitted.length > 1 && splitted.pop();
    const basename = splitted.join(".");

    return [basename, extension];
};

/**
 * Fetches the extension of a file
 */
export const extension = (filename: string) => expand(filename)[1];

/**
 * Gets a filename with a suffixed timestamp
 */
export const timestamp = (filename: string) => {
    const [basename, extension] = expand(filename);
    const timestamp = moment().format("DDMMYYYY-HHmm");

    if (extension) {
        return `${basename}_${timestamp}.${extension}`;
    }

    return `${basename}_${timestamp}`;
};
