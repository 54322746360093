// @flow

import style from "./style.module.scss";

import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import {
    TilesGrid,
    GridCol,
    FilterIcon,
    SalesPackTile,
    Filter as FilterWindow,
    DateField,
} from "@components";
import { ASSET_GRID_SIZE } from "@utils/Constants";
import { Loading, Typography, Icon, Button } from "@2po-dgp/components";
import { useSalesPacks, useSalesPacksFilterCount, useApi } from "@hooks";
import { Container } from "reactstrap";
import { SEGMENTS, LANGUAGES } from "@utils/Constants";
import { FILTERS } from "@utils/Filter";
import * as clipboard from "clipboard-polyfill/text";

const createFilter = (arr, title, fullWidth) => {
    return {
        title: title,
        fullWidth: fullWidth,
        options: arr,
    };
};

/**
 * HistoryOverview
 */
const HistoryOverview = ({ open, closeHistoryView, reload, setReload }: *) => {
    const [initials, setInitials] = useState("");
    const [appliedInitialFilter, setAppliedInitialFilter] = useState("");
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterWindowOpen, setFilterWindowOpen] = useState(false);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [copiedId, setCopiedId] = useState("");
    const [loadMore, setLoadMore] = useState(false);
    const [selected, setSelected] = useState([]);
    const [editSalespacks, setEditSalespacks] = useState(undefined);

    const APPOINTMENT_DATE = "Appointment date";
    const BASE_URL = process.env.GATSBY_PORTAL_BASE_URL || "";

    const { loading, data, error } = useSalesPacks({
        page: currentPage,
        pageSize: ASSET_GRID_SIZE,
        filterValues,
        loadMore,
        reload,
    });

    const { total, totalLoading } = useSalesPacksFilterCount({
        filterValues: selectedFilters,
    });

    const filters = [
        createFilter(["Show archived"], FILTERS.archive, true),
        createFilter(
            LANGUAGES.map(
                lang => lang.key.charAt(0).toUpperCase() + lang.key.slice(1),
            ),
            FILTERS.languages,
            false,
        ),
        createFilter(SEGMENTS, FILTERS.segments, true),
    ];

    useEffect(() => handleClear(), [open]);

    useEffect(() => updateStateValues(), [filterWindowOpen]);

    useEffect(
        () =>
            startDate &&
            endDate &&
            setSelectedFilters(addDateToFilter(selectedFilters)),
        [startDate, endDate],
    );

    useEffect(() => setSelectedFilters(addInitialsToFilter(selectedFilters)), [
        initials,
    ]);

    const { apiLoading } = useApi({
        url: "/backend/salespack/edit",
        method: "post",
        data: editSalespacks,
    });

    useEffect(() => {
        setReload(!apiLoading);
        if (!apiLoading) {
            setSelected([]);
        }
    }, [apiLoading]);

    const hideSalespacks = () => {
        setEditSalespacks(selected);
    };

    const handleClear = () => {
        setSelectedFilters(filterValues);
        setStartDate(undefined);
        setEndDate(undefined);
        setInitials("");
    };

    const handleApplyFilter = filtersToApply => {
        resetPagination();
        setFilterValues([]);
        if (startDate && endDate) {
            addDateToFilter(filtersToApply);
        }
        if (initials) {
            setAppliedInitialFilter(initials);
            addInitialsToFilter(filtersToApply);
        }
        setFilterValues(filtersToApply);
    };

    const handleInitials = val => {
        if (val !== undefined && val.length < 5) {
            setInitials(val.toUpperCase());
        }
    };

    const handleCopyLink = (salesPack: *) => {
        if (navigator && window) {
            const link = `${BASE_URL}/${salesPack["preference-language"]}/${salesPack.id}`;
            clipboard.writeText(link);
            setCopiedId(salesPack.id);
        }
    };

    const updateStateValues = () => {
        const findValByKey = key => {
            return filterValues.find(filter => filter.key === key)?.value;
        };

        const initials = findValByKey(FILTERS.initials);
        const startDate = findValByKey(FILTERS.startDate);
        const endDate = findValByKey(FILTERS.endDate);

        setInitials(initials);
        setStartDate(startDate ? startDate : undefined);
        setEndDate(endDate ? endDate : undefined);
        setSelectedFilters(filterValues);
    };

    const resetPagination = () => {
        setCurrentPage(1);
        setLoadMore(false);
    };

    const addInitialsToFilter = filters => {
        const newFilter = filters.concat();
        addFilter(newFilter, FILTERS.initials, initials);
        return newFilter;
    };

    const addDateToFilter = filters => {
        const newFilter = filters.concat();
        startDate && addFilter(newFilter, FILTERS.startDate, startDate);
        endDate && addFilter(newFilter, FILTERS.endDate, endDate);
        return newFilter;
    };

    const addFilter = (filters: *, key: string, val: *) => {
        if (filters.find(filter => filter.key === key)) {
            filters.forEach(filter => {
                if (filter.key === key) {
                    return (filter["value"] = val);
                }
                return filter;
            });
        } else {
            /* currently here as adding (val: *) fixes the problem but creates a weird layout for the following lines of code */
            //$FlowFixMe
            filters.push({ key: key, value: val });
        }
    };

    const removeFilter = (value: *) => {
        resetPagination();
        if (value === APPOINTMENT_DATE) {
            const values = filterValues.filter(
                arrayVal =>
                    arrayVal.key !== FILTERS.startDate &&
                    arrayVal.key !== FILTERS.endDate,
            );
            setFilterValues(values);
            setSelectedFilters(values);
        } else {
            const values = filterValues.filter(
                arrayVal => arrayVal.value !== value,
            );
            setFilterValues(values);
            setSelectedFilters(values);
            updateStateValues();
        }
    };

    const selectAll = () => {
        if (
            selected.length ===
            data?.salespacks.filter(pack => !pack.invisible).length
        ) {
            setSelected([]);
        } else {
            const ids = [];
            data?.salespacks.forEach(pack => {
                if (!pack.invisible) {
                    ids.push(pack.id);
                }
            });
            setSelected(ids);
        }
    };

    const toggleSelected = (value: *) => {
        if (selected.includes(value)) {
            setSelected(selected.filter(item => item !== value));
        } else {
            const newValues = [...selected, value];
            setSelected(newValues);
        }
    };

    const amountOfPages = Math.ceil(data?.totalCount / ASSET_GRID_SIZE);
    return (
        <div
            className={classnames(style.historyOverview, {
                [style.open]: open,
                [style.filterWindowOpen]: filterWindowOpen,
            })}
        >
            <Container>
                <div className={style.backButton} onClick={closeHistoryView}>
                    <Typography
                        type="panelHeaderText"
                        additionalClass={style.backButtonText}
                    >
                        <Icon
                            iconName={"listCarret"}
                            additionalClass={style.icon}
                            size="sm"
                        />
                        {"Back to overview"}
                    </Typography>
                </div>
                {((!loadMore && !loading) || loadMore) && !!data && (
                    <TilesGrid
                        className={style.searchGrid}
                        amountOfPages={amountOfPages}
                        currentPage={currentPage}
                        setCurrentPage={val => {
                            loadMore && setLoadMore(false);
                            setCurrentPage(val);
                        }}
                        onLoadMore={val => {
                            !loadMore && setLoadMore(true);
                            setCurrentPage(val);
                        }}
                        title={`Your history (${data?.totalCount})`}
                        filters={filterValues
                            ?.map(filter => {
                                if (filter.key === FILTERS.startDate) {
                                    return APPOINTMENT_DATE;
                                }
                                if (filter.key === FILTERS.initials) {
                                    return appliedInitialFilter;
                                }
                                return (
                                    filter.key !== FILTERS.endDate &&
                                    filter.value
                                );
                            })
                            .filter(val => val)}
                        removeFilter={val => removeFilter(val)}
                        displayReset={
                            filterValues?.filter(val => val.value).length > 0
                        }
                        clearQuery={() => {
                            resetPagination();
                            setFilterValues([]);
                            handleClear();
                        }}
                        filterIcon={() => (
                            <FilterIcon
                                setOpen={() =>
                                    setFilterWindowOpen(!filterWindowOpen)
                                }
                                amountOfFilters={
                                    filterValues.filter(
                                        filter =>
                                            filter.key !== FILTERS.endDate &&
                                            filter.value,
                                    )?.length
                                }
                                className={style.filterIcon}
                            />
                        )}
                    >
                        <div className={style.selected}>
                            <Button small onClick={selectAll}>
                                {selected.length === data?.salespacks.length
                                    ? "Deselect all"
                                    : "Select All"}
                            </Button>
                            <div>{selected.length} items selected</div>
                            <Button
                                small
                                disabled={selected.length === 0}
                                onClick={hideSalespacks}
                            >
                                Archive
                            </Button>
                        </div>
                        {data?.salespacks?.map((salesPack, index) => {
                            return (
                                <GridCol key={index}>
                                    {salesPack && (
                                        <SalesPackTile
                                            salesPack={salesPack}
                                            hasCopied={
                                                copiedId === salesPack.id
                                            }
                                            handleCopyLink={() =>
                                                handleCopyLink(salesPack)
                                            }
                                            toggleSelected={() =>
                                                toggleSelected(salesPack.id)
                                            }
                                            selected={selected}
                                        />
                                    )}
                                </GridCol>
                            );
                        })}
                    </TilesGrid>
                )}
                <FilterWindow
                    className={style.filter}
                    open={filterWindowOpen}
                    bottomText={
                        total === 1
                            ? `${total} salespack currently matches your selection`
                            : `${total} salespacks currently match your selection`
                    }
                    loading={totalLoading}
                    setOpen={val => setFilterWindowOpen(val)}
                    filters={filters}
                    applyFilter={val => handleApplyFilter(val)}
                    initialFilters={filterValues}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={val => setSelectedFilters(val)}
                    inputSearchField={{
                        title: "Contact",
                        placeholder: "Search by contact initials",
                        value: initials ? initials : "",
                        onChange: val => handleInitials(val),
                    }}
                    datePickerLabel={"Date"}
                    datePicker={() => (
                        <Picker
                            startDate={startDate}
                            setStartDate={val => setStartDate(val)}
                            endDate={endDate}
                            setEndDate={val => setEndDate(val)}
                        />
                    )}
                    title={"History Filter"}
                />
            </Container>
            {loading && <Loading loading={true} error={error?.message} />}
        </div>
    );
};

export default HistoryOverview;

const Picker = ({ startDate, setStartDate, endDate, setEndDate }: *) => {
    const startDateRef = useRef(null);
    const endDateRef = useRef(null);

    const openDateField = ref => {
        ref?.current?.setOpen(true);
    };
    return (
        <div className={style.datePicker}>
            <div className={style.field}>
                <DateField
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText={"Sarting on"}
                    onCalendarClose={() => {
                        if (endDateRef?.current && startDate && !endDate) {
                            openDateField(endDateRef);
                        }
                    }}
                    ref={startDateRef}
                />
            </div>
            <Typography
                type={"bodyCopy"}
                additionalClass={classnames("d-none d-md-block", style.copy)}
                color={"dark"}
            >
                {"To"}
            </Typography>
            <div
                className={style.field}
                onClick={() => {
                    if (startDateRef.current && !startDate) {
                        openDateField(startDateRef);
                    }
                }}
            >
                <DateField
                    selected={endDate}
                    onChange={date => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    placeholderText={"Ending on"}
                    disabled={!startDate}
                    ref={endDateRef}
                />
            </div>
        </div>
    );
};
