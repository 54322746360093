// @flow
import makeEnum from "@utils/enum";

const TYPES = makeEnum(["MB", "GB"]);
const bilion = 1000000000;
const milion = 1000000;

export const getFileSizeString = (value: number) => {
    if (value < bilion) {
        return `${Number((value / milion).toFixed(2))} ${TYPES.MB}`;
    }
    if (value > bilion) {
        return `${Number((value / milion).toFixed(2))} ${TYPES.GB}`;
    }
};
