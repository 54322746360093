// @flow

import { getIsoLanguage } from "@utils/NormalizePdfName";

export const buildAssetsFromItems = (items: *) => {
    return items.map(item => {
        if (item.sys.id.startsWith("unknown")) {
            return {
                ...buildBaseAssetForItem(item),
                ["upload-date"]: item.fields?.media?.sys?.updatedAt,
                ["file-size"]: item.fields?.media?.fields?.file?.details?.size,
                ["file-name"]: item.fields?.media?.fields?.file?.fileName,
            };
        } else {
            return buildBaseAssetForItem(item);
        }
    });
};

const buildBaseAssetForItem = (item: *) => {
    let language = null;
    if (item.fields.language) {
        language = getIsoLanguage(item.fields.language);
    }
    return {
        title: item.fields.title,
        ["cf-object-id"]: item.sys.id,
        id: "test",
        language,
    };
};
