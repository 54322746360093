// @flow

import React, { useState } from "react";
import axios from "axios";
import { SalesPackTile as Tile, Checkbox } from "@2po-dgp/components";
import { mapSalesPackInfo } from "@utils/SalesPack";
import moment from "moment";
import { useContentfulAssetsByIds } from "@hooks";
import { downloader, timestamp, extension } from "@utils/file";

/**
 * SalesPackTile
 */
const SalesPackTile = ({
    salesPack,
    className,
    hasCopied,
    handleCopyLink,
    toggleSelected,
    selected,
}: *) => {
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [downloadLoadingPPT, setDownloadLoadingPPT] = useState(false);

    const ContentfulAssetIds = salesPack.assets
        .filter(asset => !asset["cf-object-id"].startsWith("unknown_"))
        .map(asset => asset["cf-object-id"]);

    // const customAssets = salesPack.assets.filter(asset =>
    //     asset["cf-object-id"].startsWith("unknown_"),
    // );

    const { data } = useContentfulAssetsByIds({
        ids: ContentfulAssetIds.join(","),
    });

    // const salesPackSize =
    //     data?.length > 0 || customAssets?.length > 0
    //         ? getSalesPackSize(data, customAssets)
    //         : 0;
    const containsPpt = data?.length > 0 ? salesPackContainsPpt(data) : false;

    const handleDownloadSlides = async () => {
        setDownloadLoadingPPT(true);

        await downloader(
            `/backend/asset/downloadpptzip/${salesPack.id}`,
            timestamp(`${salesPack.id}.zip`),
        );

        setDownloadLoadingPPT(false);
    };

    const handleDownloadPdf = async () => {
        setDownloadLoading(true);

        try {
            const {
                data: { salespackName },
            } = await axios.post(`/backend/salespacks/${salesPack.id}`);

            await downloader(
                `/files/salespacks/${salespackName}?download=true`,
                timestamp(salespackName),
            );

            setDownloadLoading(false);
        } catch {
            setDownloadLoading(false);
        }
    };

    const links = [
        containsPpt && {
            label: "Download PPT",
            icon: "download",
            loading: downloadLoadingPPT,
            onClick: () => handleDownloadSlides(),
        },
        {
            label: "Download PDF",
            icon: "download",
            onClick: () => handleDownloadPdf(),
            loading: downloadLoading,
        },
    ].filter(val => val);

    return (
        <>
            <Tile
                className={className}
                assetInfo={mapSalesPackInfo(salesPack)}
                headerAction={
                    !salesPack.invisible ? (
                        <Checkbox
                            onChange={toggleSelected}
                            selected={selected.includes(salesPack.id)}
                        />
                    ) : (
                        ""
                    )
                }
                creationDateLabel={"Generated on: "}
                creationDate={moment(salesPack["creation-date"]).format(
                    "DD.MM.YYYY",
                )}
                titleArray={
                    salesPack.invisible
                        ? [
                              "Archived",
                              salesPack.initials,
                              salesPack.segment,
                              moment(salesPack["appointment-date"]).format(
                                  "DD.MM.YYYY",
                              ),
                          ]
                        : [
                              salesPack.initials,
                              salesPack.segment,
                              moment(salesPack["appointment-date"]).format(
                                  "DD.MM.YYYY",
                              ),
                          ]
                }
                button={{
                    label: hasCopied ? "Copied" : "Copy link",
                    icon: hasCopied ? "check" : "link",
                    onClick: handleCopyLink,
                    active: hasCopied,
                }}
                links={links}
            />
        </>
    );
};

export default SalesPackTile;

// const getSalesPackSize = (salesPack: *, customAssets: *) => {
//     let totalSize = 0;

//     salesPack?.forEach(asset => {
//         totalSize =
//             totalSize +
//             (asset?.fields?.media?.fields?.file?.details?.size
//                 ? asset.fields.media.fields.file.details.size
//                 : 0);
//     });

//     customAssets?.forEach(asset => {
//         totalSize = totalSize + asset["file-size"];
//     });

//     return totalSize;
// };

const salesPackContainsPpt = (salesPack: *) =>
    salesPack.some(
        asset =>
            extension(asset.fields?.media?.fields?.file?.fileName) === "pptx",
    );
