// @flow
import React, { useState } from "react";

import style from "./style.module.scss";
import { TilesGrid, GridCol } from "@components";
import {
    Panel,
    AssetTile as Tile,
    LanguageSelectorPopup,
    Button,
    Spinner,
} from "@2po-dgp/components";
import { Container } from "reactstrap";
import classnames from "classnames";

const TemplateCategory = ({
    apiTemplatesData,
    apiTemplatesLoading,
    removeTemplate,
    addTemplateToBasket,
    selectedItems,
}: *) => {
    const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);
    const [chosenTemplate, setChosenTemplate] = useState({});
    const [isConfirmRemovalOpen, setOpenConfirmRemoval] = useState(false);
    const [isConfirmAddOpen, setOpenConfirmAdd] = useState(false);

    const openConfirmRemoval = template => {
        setChosenTemplate(template);
        setOpenConfirmRemoval(true);
    };

    const openConfirmAdd = template => {
        setChosenTemplate(template);
        setOpenConfirmAdd(true);
    };

    return (
        <div>
            {apiTemplatesLoading && (
                <div className={style.loading}>
                    <Spinner />
                </div>
            )}
            {!apiTemplatesLoading && apiTemplatesData?.length > 0 && (
                <div
                    className={classnames(style.panelWrapper, {
                        [style.open]: isTemplatesOpen,
                    })}
                >
                    <Container>
                        <div className={style.panelCol}>
                            <Panel
                                category="Templates"
                                arrowLabel="Minimize"
                                defaultState={true}
                                open={isTemplatesOpen}
                                smallHeading={true}
                                onClick={() =>
                                    setIsTemplatesOpen(!isTemplatesOpen)
                                }
                            >
                                <TilesGrid
                                    amountOfPages={1}
                                    currentPage={1}
                                    setCurrentPage={() => undefined}
                                    onLoadMore={() => undefined}
                                >
                                    {apiTemplatesData &&
                                        apiTemplatesData?.map(
                                            (template, index) => {
                                                return (
                                                    <GridCol key={index}>
                                                        <Tile
                                                            category="Templates"
                                                            className={
                                                                style.templateCard
                                                            }
                                                            title={
                                                                template?.templateName
                                                            }
                                                            copy={
                                                                template?.templateDescription
                                                            }
                                                            buttonA={{
                                                                className:
                                                                    "d-md-block",
                                                                icon:
                                                                    "removeBasket",
                                                                onClick: () =>
                                                                    openConfirmRemoval(
                                                                        template,
                                                                    ),
                                                            }}
                                                            buttonB={{
                                                                className: classnames(
                                                                    "ml-auto",
                                                                    style.addButton,
                                                                ),
                                                                label:
                                                                    "Use template",
                                                                size: "xs",
                                                                onClick: () =>
                                                                    selectedItems?.length ===
                                                                    0
                                                                        ? addTemplateToBasket(
                                                                              template,
                                                                          )
                                                                        : openConfirmAdd(
                                                                              template,
                                                                          ),
                                                            }}
                                                        />
                                                    </GridCol>
                                                );
                                            },
                                        )}
                                </TilesGrid>
                            </Panel>
                        </div>
                        {isConfirmAddOpen && (
                            <LanguageSelectorPopup
                                title={`Applying this template (${chosenTemplate.templateName}) will erase your current basket, are you sure?`}
                                content={
                                    <div className={style.actions}>
                                        <Button
                                            transparent
                                            onClick={() => {
                                                setChosenTemplate({});
                                                setOpenConfirmAdd(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                addTemplateToBasket(
                                                    chosenTemplate,
                                                );
                                                setOpenConfirmAdd(false);
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    </div>
                                }
                                onClose={() => setOpenConfirmAdd(false)}
                            ></LanguageSelectorPopup>
                        )}
                        {isConfirmRemovalOpen && (
                            <LanguageSelectorPopup
                                title={`Are you sure you want to remove this template: ${chosenTemplate.templateName}?`}
                                content={
                                    <div className={style.actions}>
                                        <Button
                                            transparent
                                            onClick={() => {
                                                setChosenTemplate({});
                                                setOpenConfirmRemoval(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                removeTemplate(chosenTemplate);
                                                setOpenConfirmRemoval(false);
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    </div>
                                }
                                onClose={() => setOpenConfirmRemoval(false)}
                            ></LanguageSelectorPopup>
                        )}
                    </Container>
                </div>
            )}
        </div>
    );
};

export default TemplateCategory;
