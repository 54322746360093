// @flow
import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Toolbar, PreviewModal, SaveTemplate } from "@components";
import {
    Loading,
    Typography,
    LanguageSelectorPopup,
    Button,
} from "@2po-dgp/components";
import { Container } from "reactstrap";
import { setCookie, getCookie } from "@utils/Cookie";
import { ASSET_GRID_SIZE, LANGUAGES } from "@utils/Constants";
import { Categories, Search, HistoryOverview, HelpSection } from "@containers";
import { useDispatch, useSelector } from "react-redux";
import { load as loadUser } from "@redux/user";

import { useContentfulAssets } from "@hooks";
import {
    loadLocalStorage as load,
    saveLocalStorage as save,
} from "@utils/Storage";
import {
    STORAGE_SELECTED_ITEMS,
    POPUP_DISMISSED,
    DRAWER_OPENED,
} from "@utils/Constants";
import useApi from "@hooks/useApi";
import { getAssets, generateUploadedFileStructure } from "../../utils/Asset";

const Application = () => {
    const [query, setQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [filterValues, setFilterValues] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [historyOverviewOpen, setHistoryOverviewOpen] = useState(false);
    const [helpViewOpen, setHelpViewOpen] = useState(false);
    const [filterWindowOpen, setFilterWindowOpen] = useState(false);
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
    const [showLanguageSelectorPopup, setShowLanguageSelectorPopup] = useState(
        false,
    );
    const [showSaveTemplatePopup, setShowSaveTemplatePopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState(
        load(STORAGE_SELECTED_ITEMS),
    );
    const [templates, setTemplates] = useState([]);
    const [previewModalData, setPreviewModalData] = useState(undefined);

    const [chosenLanguage, setchosenLanguage] = useState(undefined);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const changeLanguage = value => {
        setCookie("chosenLanguages", value);
        setchosenLanguage(value);

        if (showLanguageSelectorPopup) {
            setShowLanguageSelectorPopup(false);
        }
    };

    const showSaveTemplate = () => {
        setShowSaveTemplatePopup(true);
    };

    const closeSaveTemplate = (isSaved: boolean = false) => {
        setShowSaveTemplatePopup(false);
        setSideDrawerOpen(false);
        refetch();
        if (isSaved) {
            handleSelectedItems([]);
        }
    };

    const limit = ASSET_GRID_SIZE;

    useEffect(() => {
        const cookie = getCookie("chosenLanguages");

        if (cookie && cookie !== "") {
            let chosenLanguage = "";
            // Catch for previous development with multiple languages
            if (cookie.indexOf(",") > -1) {
                chosenLanguage = cookie?.trim()?.split(",")[0];
            } else {
                chosenLanguage = cookie;
            }
            setchosenLanguage(chosenLanguage || []);
        } else {
            setShowLanguageSelectorPopup(true);
            setchosenLanguage(LANGUAGES[0]?.key);
        }
    }, [setchosenLanguage]);

    const { loading, error, data } = useContentfulAssets(
        {
            content_type: "saleshubAsset",
            skip: 0,
            limit,
            query,
            language: chosenLanguage,
        },
        [chosenLanguage],
    );

    const { apiLoading, apiData } = useApi({
        url: "/backend/salespacks",
        method: "get",
        params: {
            page: 1,
            pageSize: 1,
        },
    });

    const {
        apiLoading: apiTemplatesLoading,
        apiData: apiTemplatesData,
        refetch,
    } = useApi({
        url: "/backend/templates",
        method: "get",
    });

    useEffect(() => {
        dispatch(loadUser());
    }, [dispatch]);

    useEffect(() => {
        save(STORAGE_SELECTED_ITEMS, selectedItems);
    }, [selectedItems]);

    useEffect(() => {
        previewModalData ||
        historyOverviewOpen ||
        filterWindowOpen ||
        sideDrawerOpen
            ? setAddNoScroll(true)
            : setAddNoScroll(false);
    }, [
        previewModalData,
        historyOverviewOpen,
        filterWindowOpen,
        sideDrawerOpen,
    ]);

    const setAddNoScroll = bool => {
        if (typeof window !== "undefined" && document) {
            bool
                ? document.body?.classList?.add("noScroll")
                : document.body?.classList?.remove("noScroll");
        }
    };

    useEffect(() => {
        if (!apiLoading && !load(POPUP_DISMISSED)) {
            if (apiData?.salespacks?.length && apiData.salespacks.length > 0) {
                const twoMonthsAgo = new Date();
                twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

                if (
                    twoMonthsAgo >
                    new Date(apiData.salespacks[0]["creation-date"])
                ) {
                    setShowPopup(true);
                }
            } else {
                setShowPopup(true);
            }
        }
    }, [apiLoading]);

    useEffect(() => {
        if (!apiTemplatesLoading) {
            if (
                apiTemplatesData?.templates?.length &&
                apiTemplatesData.templates.length > 0
            ) {
                setTemplates(
                    apiTemplatesData.templates.filter(
                        temp => temp.chosenLanguage === chosenLanguage,
                    ),
                );
            }
        }
    }, [apiTemplatesLoading, chosenLanguage]);

    const removeSelectedValue = (value: *) => {
        setFilterValues(
            filterValues.filter(arrayVal => arrayVal.value !== value),
        );
        setSelectedFilters(
            filterValues.filter(arrayVal => arrayVal.value !== value),
        );
    };

    const clearQuery = () => {
        setQuery("");
        setFilterValues([]);
        setSelectedFilters([]);
    };

    const handleSelectedItems = items => {
        save(STORAGE_SELECTED_ITEMS, items);
        setSelectedItems(items);
    };

    const handlePopupDismiss = () => {
        save(POPUP_DISMISSED, true);
        setShowPopup(false);
    };

    const handleSideDrawerOpening = () => {
        setSideDrawerOpen(true);
        save(DRAWER_OPENED, true);
    };

    const addAssetToSelection = asset => {
        const assets = selectedItems?.concat() || [];
        load(DRAWER_OPENED) ? null : handleSideDrawerOpening();
        assets.push(asset);
        handleSelectedItems(assets);
    };

    const addTemplateToBasket = async (template: *) => {
        let retrievedAssets = [];
        await getAssets(
            template.assets.map(asset => asset["cf-object-id"]),
        ).then(response => {
            retrievedAssets = response;
        });

        template.assets
            ?.filter(
                uploadedAsset =>
                    uploadedAsset["cf-object-id"].indexOf("unknown_") > -1,
            )
            ?.forEach(uploaded => {
                retrievedAssets.push(
                    generateUploadedFileStructure(
                        uploaded,
                        uploaded.title,
                        true,
                    ),
                );
            });

        let sortedAssets = [];

        template.assets.forEach(asset => {
            sortedAssets.push(
                retrievedAssets.filter(
                    retrieved => retrieved.sys.id === asset["cf-object-id"],
                )[0],
            );
        });

        handleSelectedItems(sortedAssets);
        handleSideDrawerOpening();
    };

    const removeTemplate = template => {
        axios({
            url: `/backend/templates/delete/${template.id}`,
            method: "get",
        }).then(() => {
            refetch();
        });
    };

    const removeAssetFromSelection = asset => {
        const assets = selectedItems?.concat() || [];
        const index = assets?.map(asset => asset.sys?.id).indexOf(asset.sys.id);
        if (index > -1) {
            assets.splice(index, 1);
        }
        handleSelectedItems(assets);
    };

    if (user.data && data?.items) {
        return (
            <div className={style.application}>
                <Toolbar
                    showSearch
                    query={query}
                    setQuery={val => setQuery(val)}
                    clearQuery={() => {
                        clearQuery();
                    }}
                    isLoading={isLoading}
                    onStatusChange={val => setIsLoading(val)}
                    updateSelect={val => handleSelectedItems(val)}
                    selectedItems={selectedItems}
                    setReload={val => setReload(val)}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={val => setSelectedFilters(val)}
                    applyFilter={val => setFilterValues(val)}
                    initialFilters={filterValues}
                    sideDrawerOpen={sideDrawerOpen}
                    setSideDrawerOpen={val => setSideDrawerOpen(val)}
                    filterWindowOpen={filterWindowOpen}
                    setFilterWindowOpen={val => setFilterWindowOpen(val)}
                    helpViewOpen={helpViewOpen}
                    setHistoryOverviewOpen={() =>
                        setHistoryOverviewOpen(!historyOverviewOpen)
                    }
                    setHelpViewOpen={() => setHelpViewOpen(!helpViewOpen)}
                    setAddNoScroll={val => setAddNoScroll(val)}
                    chosenLanguage={chosenLanguage}
                    changeLanguage={val => changeLanguage(val)}
                    showSaveTemplate={showSaveTemplate}
                />
                {showLanguageSelectorPopup && (
                    <LanguageSelectorPopup
                        title="In what language would you like to generate a salespack?"
                        content={LANGUAGES.map(({ value, key }: any, index) => (
                            <div
                                key={index}
                                className={style.languageSelectorButton}
                            >
                                <Button
                                    transparent
                                    cover
                                    minWidth={12}
                                    onClick={() =>
                                        changeLanguage && changeLanguage(key)
                                    }
                                >
                                    {value}
                                </Button>
                            </div>
                        ))}
                        onClose={() => changeLanguage(LANGUAGES[0]?.key)}
                    />
                )}
                <SaveTemplate
                    chosenLanguage={chosenLanguage}
                    selectedItems={selectedItems}
                    showSaveTemplatePopup={showSaveTemplatePopup}
                    closeSaveTemplate={closeSaveTemplate}
                />
                {query || filterValues?.length > 0 ? (
                    <Container>
                        <Search
                            query={query}
                            addAssetToSelection={val =>
                                addAssetToSelection(val)
                            }
                            removeAssetFromSelection={val =>
                                removeAssetFromSelection(val)
                            }
                            selectedItems={selectedItems}
                            filterValues={filterValues}
                            clearQuery={() => {
                                clearQuery();
                            }}
                            removeFilter={val => removeSelectedValue(val)}
                            setPreviewModalData={val =>
                                setPreviewModalData(val)
                            }
                            chosenLanguage={chosenLanguage}
                        />
                    </Container>
                ) : (
                    <Container fluid className={"px-0"}>
                        <Categories
                            addAssetToSelection={val =>
                                addAssetToSelection(val)
                            }
                            removeAssetFromSelection={val =>
                                removeAssetFromSelection(val)
                            }
                            selectedItems={selectedItems}
                            showPopup={showPopup}
                            handlePopupDismiss={() => handlePopupDismiss()}
                            setPreviewModalData={val =>
                                setPreviewModalData(val)
                            }
                            chosenLanguage={chosenLanguage}
                            apiTemplatesData={templates}
                            apiTemplatesLoading={apiTemplatesLoading}
                            removeTemplate={removeTemplate}
                            addTemplateToBasket={addTemplateToBasket}
                        />
                    </Container>
                )}
                <HelpSection
                    open={helpViewOpen}
                    closeHelpSection={() => setHelpViewOpen(false)}
                />
                <HistoryOverview
                    open={historyOverviewOpen}
                    closeHistoryView={() => setHistoryOverviewOpen(false)}
                    reload={reload}
                    setReload={val => setReload(val)}
                />
                <PreviewModal
                    previewModalData={previewModalData}
                    setPreviewModalData={setPreviewModalData}
                    selectedItems={selectedItems}
                    removeAsset={val => removeAssetFromSelection(val)}
                    addAsset={val => addAssetToSelection(val)}
                    className={"d-none d-md-block"}
                />
            </div>
        );
    }

    return (
        <div className={style.loading}>
            <Loading loading={loading} error={error?.message} />
            <Typography type="filterTitle">
                We are securely logging you into the application. Please hold
                on.
            </Typography>
        </div>
    );
};

export default Application;
