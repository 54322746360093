// @flow
import React, { useEffect, useState } from "react";
import style from "./style.module.scss";
import { TilesGrid, AssetTile, GridCol } from "@components";
import { Panel, Spinner } from "@2po-dgp/components";
import { useContentfulCategoryAssets } from "@hooks";
import { ASSET_GRID_SIZE } from "@utils/Constants";
import { Container } from "reactstrap";
import classnames from "classnames";

type Props = {
    category: *,
    data?: *,
    isPanelOpen?: boolean,
    addAssetToSelection: *,
    removeAssetFromSelection: *,
    selectedItems: *,
    loading?: *,
    useCustomDataSet?: boolean,
    onCurrentPageChange?: *,
    onLoadMore?: *,
    activePage?: *,
    onDataReceived?: *,
    showPopup: boolean,
    handlePopupDismiss: *,
    setPreviewModalData: *,
    chosenLanguage: *,
};

type LayoutProps = {
    category: *,
    data?: *,
    isPanelOpen?: boolean,
    selectedItems: *,
    loading?: boolean,
    loadMore?: boolean,
    currentPage: number,
    setCurrentPage: *,
    onLoadMore: *,
    open: boolean,
    setOpen: *,
    showPopup: boolean,
    handlePopupDismiss: *,
    setPreviewModalData: *,
    removeAssetFromSelection: *,
    addAssetToSelection: *,
};

const Layout = ({
    category,
    data,
    isPanelOpen,
    currentPage,
    setCurrentPage,
    onLoadMore,
    open,
    setOpen,
    loading,
    loadMore,
    selectedItems,
    handlePopupDismiss,
    showPopup,
    setPreviewModalData,
    removeAssetFromSelection,
    addAssetToSelection,
}: LayoutProps) => {
    const total = data ? data.total : 0;
    const amountOfPages = Math.ceil(total / ASSET_GRID_SIZE);

    return (
        <div className={classnames(style.panelWrapper, { [style.open]: open })}>
            <Container>
                <div className={style.panelCol}>
                    <Panel
                        category={category.title}
                        arrowLabel="Minimize"
                        defaultState={isPanelOpen}
                        open={open}
                        onClick={setOpen}
                        smallHeading={true}
                    >
                        {((!loadMore && !loading) || loadMore) && !!data && (
                            <TilesGrid
                                currentPage={currentPage}
                                amountOfPages={amountOfPages}
                                setCurrentPage={number =>
                                    setCurrentPage(number)
                                }
                                onLoadMore={number => onLoadMore(number)}
                            >
                                {data.items.map((asset, index) => {
                                    return (
                                        <GridCol key={index}>
                                            <AssetTile
                                                asset={asset}
                                                selected={selectedItems?.find(
                                                    selectedAsset =>
                                                        selectedAsset?.sys
                                                            ?.id ===
                                                        asset?.sys?.id,
                                                )}
                                                setPreviewModalData={val =>
                                                    setPreviewModalData(val)
                                                }
                                                removeAsset={val =>
                                                    removeAssetFromSelection(
                                                        val,
                                                    )
                                                }
                                                addAsset={val =>
                                                    addAssetToSelection(val)
                                                }
                                                showPopup={
                                                    index === 0 && showPopup
                                                }
                                                handlePopupDismiss={val =>
                                                    handlePopupDismiss(val)
                                                }
                                            />
                                        </GridCol>
                                    );
                                })}
                            </TilesGrid>
                        )}
                        {loading && (
                            <div className={style.spinnerWrapper}>
                                <Spinner className={style.spinner} />
                            </div>
                        )}
                    </Panel>
                </div>
            </Container>
        </div>
    );
};

const CategoryAssets = ({
    category,
    data,
    isPanelOpen,
    addAssetToSelection,
    removeAssetFromSelection,
    selectedItems,
    loading,
    useCustomDataSet,
    onCurrentPageChange,
    onLoadMore,
    activePage,
    onDataReceived,
    showPopup,
    handlePopupDismiss,
    setPreviewModalData,
    chosenLanguage,
}: Props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [loadMore, setLoadmore] = useState(false);
    const [open, setOpen] = useState(isPanelOpen || false);

    const skip = loadMore ? 0 : (currentPage - 1) * ASSET_GRID_SIZE;
    const limit = loadMore ? currentPage * ASSET_GRID_SIZE : ASSET_GRID_SIZE;

    useEffect(() => {
        if (isPanelOpen) {
            setOpen(isPanelOpen);
        }
    }, [isPanelOpen]);

    if (useCustomDataSet && data?.items) {
        return data.items?.length > 0 ? (
            <Layout
                category={category}
                data={data}
                currentPage={activePage ? activePage : currentPage}
                setCurrentPage={number => {
                    loadMore && setLoadmore(false);
                    onCurrentPageChange && onCurrentPageChange(number);
                    setCurrentPage(number);
                }}
                onLoadMore={number => {
                    !loadMore && setLoadmore(true);
                    onLoadMore && onLoadMore(number);
                    setCurrentPage(number);
                }}
                open={open}
                setOpen={bool => setOpen(bool)}
                addAssetToSelection={val => addAssetToSelection(val)}
                removeAssetFromSelection={val => removeAssetFromSelection(val)}
                selectedItems={selectedItems}
                loading={loading}
                loadMore={loadMore}
                showPopup={showPopup}
                handlePopupDismiss={handlePopupDismiss}
                setPreviewModalData={val => setPreviewModalData(val)}
            />
        ) : null;
    }

    const {
        categoryAssets,
        categoryAssetsLoading,
    } = useContentfulCategoryAssets({
        skip: skip,
        limit: limit,
        category: category.slug,
        language: chosenLanguage,
    });

    useEffect(
        () =>
            onDataReceived && onDataReceived(categoryAssets?.items?.length > 0),
        [categoryAssets?.items?.length],
    );

    if (categoryAssets?.items?.length > 0) {
        return (
            <Layout
                loading={categoryAssetsLoading}
                loadMore={loadMore}
                category={category}
                data={categoryAssets}
                currentPage={currentPage}
                setCurrentPage={number => {
                    loadMore && !setLoadmore(false);
                    setCurrentPage(number);
                }}
                onLoadMore={number => {
                    !loadMore && setLoadmore(true);
                    setCurrentPage(number);
                }}
                open={open}
                setOpen={bool => setOpen(bool)}
                selectedItems={selectedItems}
                showPopup={showPopup}
                handlePopupDismiss={handlePopupDismiss}
                addAssetToSelection={val => addAssetToSelection(val)}
                removeAssetFromSelection={val => removeAssetFromSelection(val)}
                setPreviewModalData={val => setPreviewModalData(val)}
            />
        );
    }
    return null;
};

export default CategoryAssets;
