/* eslint-disable smells/no-switch */
// @flow

import style from "./style.module.scss";

import React, { useState, useEffect } from "react";

import {
    Toolbar as TB,
    Typography,
    Icon,
    Orb,
    Link,
    SearchField,
    NotificationMessage,
    LanguageSelector,
    Popup,
} from "@2po-dgp/components";

import Logo from "../../images/dgp_logo.inline.svg";
import { SideDrawer, Filter as FilterWindow, FilterIcon } from "@components";
import classnames from "classnames";
import { SEGMENT_NAMES, LANGUAGES } from "@utils/Constants";
import { useContentfulCategories } from "@hooks";
import { Container } from "reactstrap";
import { useContentfulFilterCount } from "@hooks";
import {
    loadLocalStorage as load,
    saveLocalStorage as save,
} from "@utils/Storage";
import { INFO_POPUP_DISMISSED } from "@utils/Constants";
import { useSelector } from "react-redux";

type Props = {
    showSearch?: boolean,
    query: *,
    setQuery: *,
    clearQuery: *,
    isLoading?: boolean,
    onStatusChange: *,
    selectedItems: *,
    updateSelect: *,
    setReload: *,
    applyFilter: *,
    initialFilters: *,
    helpViewOpen: *,
    selectedFilters: *,
    setSelectedFilters: *,
    setHistoryOverviewOpen: *,
    setHelpViewOpen: *,
    setAddNoScroll: *,
    sideDrawerOpen: boolean,
    setSideDrawerOpen: *,
    filterWindowOpen: boolean,
    setFilterWindowOpen: *,
    chosenLanguage: *,
    changeLanguage: *,
    showSaveTemplate: *,
};

const createFilter = (arr, title, fullWidth) => {
    return {
        title: title,
        fullWidth: fullWidth,
        options: arr,
    };
};

/**
 * Toolbar
 */
const Toolbar = ({
    showSearch,
    query,
    setQuery,
    clearQuery,
    isLoading,
    onStatusChange,
    selectedItems,
    updateSelect,
    setReload,
    applyFilter,
    initialFilters,
    helpViewOpen,
    selectedFilters,
    setSelectedFilters,
    setHistoryOverviewOpen,
    setHelpViewOpen,
    setAddNoScroll,
    sideDrawerOpen,
    setSideDrawerOpen,
    filterWindowOpen,
    setFilterWindowOpen,
    chosenLanguage,
    changeLanguage,
    showSaveTemplate,
}: Props) => {
    const { categories } = useContentfulCategories();

    const { loading, total } = useContentfulFilterCount({
        filterValues: selectedFilters,
        query: query,
        language: chosenLanguage,
    });

    const [isOffline, setIsOffline] = useState(false);
    const [showOnline, setShowOnline] = useState(false);
    const [showPopup, setShowPopup] = useState(true);

    useEffect(() => {
        if (load(INFO_POPUP_DISMISSED)) {
            setShowPopup(false);
        }
    });

    useEffect(() => {
        window.addEventListener("online", () => {
            setIsOffline(false);
            setShowOnline(true);
            setTimeout(() => {
                setShowOnline(false);
            }, 3000);
        });
        window.addEventListener("offline", () => {
            setIsOffline(true);
            setShowOnline(false);
        });
    }, [setIsOffline, setShowOnline]);

    const filters = [
        createFilter(
            Object.entries(SEGMENT_NAMES).map(name => ({
                value: name[0],
                label: name[1],
            })),
            "Segments",
            true,
        ),
        createFilter(
            categories?.items.map(cat => cat.fields.title),
            "Categories",
            true,
        ),
    ];

    const languages = LANGUAGES.map(
        lang => lang.key.charAt(0).toUpperCase() + lang.key.slice(1),
    );

    if (filterWindowOpen || sideDrawerOpen) {
        setAddNoScroll(true);
    }

    const popupCopy = "To see what is new in this version, click here";

    const closeInfoCircle = () => {
        save(INFO_POPUP_DISMISSED, true);
        setShowPopup(false);
    };

    return (
        <>
            <div className={style.wrapper}>
                <Container>
                    <NotificationMessage
                        type="error"
                        size="fit"
                        position="fixed"
                        informationLabelText="Connection is lost"
                        showMessage={isOffline}
                    >
                        You lost network connection. Check your internet
                        connection. If you still encounter issues contact IT
                        service desk (
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://degroofpetercam.service-now.com/sp"
                        >
                            https://degroofpetercam.service-now.com/sp
                        </a>
                        )
                    </NotificationMessage>
                    <NotificationMessage
                        type="success"
                        size="fit"
                        position="fixed"
                        informationLabelText="Connection is restored"
                        showMessage={showOnline}
                    />
                    <TB
                        logo={
                            <a
                                className={style.inlineLogo}
                                href={`/`}
                                onClick={() => {
                                    clearQuery();
                                    setSideDrawerOpen(false);
                                }}
                            >
                                <Logo
                                    className={classnames({
                                        [style.loading]: isLoading,
                                    })}
                                />
                            </a>
                        }
                        className={style.tb}
                    >
                        {showSearch && (
                            <>
                                <MobileSearch
                                    query={query}
                                    setQuery={setQuery}
                                    className={"d-md-none"}
                                >
                                    <FilterIcon
                                        amountOfFilters={initialFilters?.length}
                                        setOpen={() =>
                                            setFilterWindowOpen(
                                                !filterWindowOpen,
                                            )
                                        }
                                        open={filterWindowOpen}
                                        filters={initialFilters}
                                    />
                                </MobileSearch>
                                <Search
                                    query={query}
                                    setQuery={setQuery}
                                    className={"d-none d-md-flex"}
                                >
                                    <FilterIcon
                                        amountOfFilters={initialFilters?.length}
                                        setOpen={() =>
                                            setFilterWindowOpen(
                                                !filterWindowOpen,
                                            )
                                        }
                                        open={filterWindowOpen}
                                        filters={initialFilters}
                                    />
                                </Search>
                            </>
                        )}
                        <div className={style.toolbarItems}>
                            <LanguageSelector
                                languages={languages}
                                selectedLanguage={chosenLanguage}
                                onLanguageChange={val => changeLanguage(val)}
                            />
                            <User setOpen={setHistoryOverviewOpen} />
                        </div>
                        <div className={style.helpSection}>
                            <Icon
                                variant="primary"
                                iconName="infoCircle"
                                onClick={() => setHelpViewOpen(!helpViewOpen)}
                            />
                            {showPopup && (
                                <Popup
                                    positionAtBottom
                                    icon="infoCircle"
                                    copy={popupCopy}
                                    onClose={() => closeInfoCircle()}
                                />
                            )}
                        </div>
                        <Basket
                            selectedItems={selectedItems}
                            setOpen={val => setSideDrawerOpen(val)}
                            open={sideDrawerOpen}
                        />
                    </TB>
                </Container>
            </div>
            <FilterWindow
                open={filterWindowOpen}
                setOpen={val => setFilterWindowOpen(val)}
                filters={filters}
                applyFilter={val => applyFilter(val)}
                initialFilters={initialFilters}
                selectedFilters={selectedFilters}
                setSelectedFilters={val => setSelectedFilters(val)}
                loading={loading}
                bottomText={
                    total === 1
                        ? `${total} file currently matches your selection`
                        : `${total} files currently match your selection`
                }
            />
            <SideDrawer
                className={style.drawer}
                sideDrawerOpen={sideDrawerOpen}
                closeSideDrawer={() => setSideDrawerOpen(false)}
                onStatusChange={val => onStatusChange(val)}
                selectedItems={selectedItems}
                updateSelect={val => updateSelect(val)}
                setReload={val => setReload(val)}
                setHistoryOverviewOpen={setHistoryOverviewOpen}
                setHelpViewOpen={setHelpViewOpen}
                showSaveTemplate={showSaveTemplate}
            />
        </>
    );
};

export default Toolbar;

const Search = ({ query, setQuery, className, children }: *) => (
    <SearchField
        placeholder={"Search"}
        value={query}
        onChange={e => setQuery(e.target.value ? e.target.value : "")}
        className={classnames(className, style.searchField, {
            [style.open]: open,
        })}
    >
        {children}
    </SearchField>
);

const MobileSearch = ({ query, setQuery, className, children }: *) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined" && document) {
            open
                ? document.body?.classList?.add("searchOpen")
                : document.body?.classList?.remove("searchOpen");
        }
    }, [open]);

    return (
        <div
            className={classnames(className, style.mobileSearch, {
                [style.open]: open,
            })}
        >
            <Icon
                iconName="search"
                className={style.searchIcon}
                onClick={() => setOpen(!open)}
            />
            <div className={style.mobileSearchFieldWrapper}>
                <Search
                    query={query}
                    setQuery={setQuery}
                    className={style.mobileSearchField}
                >
                    {children}
                </Search>
            </div>
        </div>
    );
};

const Basket = ({ selectedItems, setOpen, open }: *) => {
    return (
        <div className={style.basket} onClick={() => setOpen(!open)}>
            <Icon iconName="bigBasket" variant="neutral" size="lg" />
            {selectedItems?.length > 0 && (
                <Orb className={style.basketOrb}>{selectedItems?.length}</Orb>
            )}
            <Icon
                iconName="listCarret"
                variant="neutral"
                size="md"
                additionalClass={classnames(
                    "d-none d-md-flex",
                    style.listCarret,
                    {
                        [style.open]: open,
                    },
                )}
            />
        </div>
    );
};

const User = ({ setOpen }: *) => {
    const user = useSelector(({ user }) => user);

    const username = user.data?.name || "";

    return (
        <div className={style.user} onClick={setOpen}>
            <div className={"d-none d-lg-block"}>
                <div className={style.username}>
                    <Typography type="heading-4" color="black">
                        {"Hi, "}
                    </Typography>
                    <Typography type="heading-4" color="dark">
                        {username}
                    </Typography>
                </div>
                <Link type="historyLink" className={style.link}>
                    {"History"}
                </Link>
            </div>
            <Icon
                iconName="history"
                variant="neutral"
                additionalClass={classnames("d-lg-none", style.userIcon)}
            />
        </div>
    );
};
