// @flow

/**
 * Converts an array of strings into an enum
 */
export default (values: string[]): Object =>
    Object.freeze(
        values.reduce(
            (accumulator, current) => ({
                ...accumulator,
                [current]: current,
            }),
            {},
        ),
    );
