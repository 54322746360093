// @flow
import style from "./style.module.scss";

import React from "react";
import Header from "../Header";
import CTAs from "../CTAs";

const Success = ({
    hasCopied,
    handleCopy,
    handleDownload,
    downloadLoading,
    downloadLoadingPPT,
    handleDownloadSlides,
    handleHistoryLink,
    showDownloadSlidesButton,
}: *) => (
    <div className={style.success}>
        <Header
            className={style.header}
            title={"Ready!"}
            copy={
                "SalesHub successfully created a unique link. Please copy the link to share with your contact."
            }
        />
        <CTAs
            className={style.ctas}
            buttons={[
                {
                    label: hasCopied ? "Copied" : "Copy URL",
                    icon: hasCopied ? "check" : "link",
                    active: hasCopied,
                    onClick: handleCopy,
                },
                {
                    label: "Download as 1 PDF",
                    icon: "download",
                    onClick: handleDownload,
                    loading: downloadLoading,
                },
                showDownloadSlidesButton && {
                    label: "Download PPT zip",
                    icon: "download",
                    onClick: handleDownloadSlides,
                    loading: downloadLoadingPPT,
                },
            ].filter(v => v)}
            copy={"SalesHub added the link to"}
            link={{
                label: "your profile history",
                onClick: handleHistoryLink,
            }}
        />
    </div>
);

export default Success;
