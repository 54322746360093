// @flow
import React, { useState } from "react";
import { CategoryAssets, TemplateCategory } from "@components";
import { Loading } from "@2po-dgp/components";
import { useContentfulCategories } from "@hooks";

const Categories = ({
    addAssetToSelection,
    removeAssetFromSelection,
    selectedItems,
    handlePopupDismiss,
    showPopup,
    setPreviewModalData,
    chosenLanguage,
    apiTemplatesData,
    apiTemplatesLoading,
    removeTemplate,
    addTemplateToBasket,
}: *) => {
    let foundCategoryWithItems = false;

    const {
        categories,
        categoriesLoading,
        categoriesError,
    } = useContentfulCategories();

    const [categoriesData, setCategoriesData] = useState({});

    const updateCategoryData = (categorySlug: *, hasData: *) => {
        const newCats = { ...categoriesData };
        newCats[categorySlug] = hasData;
        setCategoriesData(newCats);
    };

    if (categories?.items) {
        return (
            <div>
                <TemplateCategory
                    apiTemplatesData={apiTemplatesData}
                    apiTemplatesLoading={apiTemplatesLoading}
                    removeTemplate={removeTemplate}
                    selectedItems={selectedItems}
                    addTemplateToBasket={addTemplateToBasket}
                />
                {categories &&
                    categories.items.map((category, index) => {
                        const categoryAssets = (
                            <CategoryAssets
                                key={index}
                                category={category.fields}
                                addAssetToSelection={val =>
                                    addAssetToSelection(val)
                                }
                                removeAssetFromSelection={val =>
                                    removeAssetFromSelection(val)
                                }
                                selectedItems={selectedItems}
                                isPanelOpen={false}
                                handlePopupDismiss={handlePopupDismiss}
                                showPopup={
                                    showPopup &&
                                    categoriesData[category.fields.slug] &&
                                    !foundCategoryWithItems
                                }
                                onDataReceived={hasData =>
                                    updateCategoryData(
                                        category.fields.slug,
                                        hasData,
                                    )
                                }
                                setPreviewModalData={val =>
                                    setPreviewModalData(val)
                                }
                                chosenLanguage={chosenLanguage}
                            />
                        );
                        if (!foundCategoryWithItems) {
                            foundCategoryWithItems =
                                categoriesData[category.fields.slug];
                        }
                        return categoryAssets;
                    })}
            </div>
        );
    }

    return (
        <Loading loading={categoriesLoading} error={categoriesError?.message} />
    );
};

export default Categories;
