// @flow

import {
    LANGUAGE_DROPDOWN_DEFAULT,
    SEGMENT_DROPDOWN_DEFAULT,
} from "@utils/Constants";
// import { getFileSizeString } from "@utils/FileSize";
import { buildAssetsFromItems } from "@utils/BuildAssets";

export const createSalesPackBody = (
    initials: *,
    segment: *,
    preferenceLanguage: *,
    appointmentDate: *,
    items: *,
    confirmation?: boolean,
) => {
    return {
        initials,
        segment: validateDropdown(segment, SEGMENT_DROPDOWN_DEFAULT),
        assets: buildAssetsFromItems(items),
        ["preference-language"]: validateDropdown(
            preferenceLanguage,
            LANGUAGE_DROPDOWN_DEFAULT,
        ),
        ["appointment-date"]: appointmentDate,
        ["creation-date"]: new Date(),
        confirmation,
    };
};

export const getSalespackObject = (
    initials?: string,
    segment?: string,
    preferenceLanguage: *,
    date?: *,
    confirmed?: boolean,
) => ({
    initials: initials,
    segment: validateDropdown(segment, SEGMENT_DROPDOWN_DEFAULT),
    preferenceLanguage: validateDropdown(
        preferenceLanguage,
        LANGUAGE_DROPDOWN_DEFAULT,
    ),
    date: date,
    confirmed: confirmed,
});

const validateDropdown = (value, matchValue) => {
    if (value === "" || value === matchValue) {
        return "";
    }
    return value;
};

export const mapSalesPackInfo = (salesPack: *) => {
    const length = salesPack.assets?.length;
    return [
        {
            label: `${length} ${length > 1 ? "files" : "file"}`,
            icon: "smallBasket",
        },
        {
            label: salesPack["preference-language"].toUpperCase(),
            icon: "language",
        },
        // {
        //     label: getFileSizeString(size),
        //     icon: "size",
        // },
    ];
};
