// @flow

import style from "./style.module.scss";

import React from "react";
import { Typography, Orb, Icon } from "@2po-dgp/components";
import classnames from "classnames";

/**
 * FilterIcon
 */
const FilterIcon = ({ setOpen, open, amountOfFilters, className }: *) => (
    <Typography
        type="filterTitle"
        additionalClass={classnames(className, style.filter, {
            [style.open]: open,
        })}
        onClick={setOpen}
    >
        <Icon iconName="filter" variant="neutral" size="md" />
        {amountOfFilters > 0 && <Orb className={style.filterOrb}></Orb>}
    </Typography>
);

export default FilterIcon;
