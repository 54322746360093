import { useState, useEffect } from "react";
import getClient from "@utils/Client";
import { filterMissingAssets } from "@utils/Asset";
// @flow

// use this for searching assets
const contentfulAssetsByIds = ({ ids }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const client = getClient();

    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        "sys.id[in]": ids,
        "fields.lastProcessed[exists]": true,
    };

    const getAssets = async () => {
        try {
            setLoading(true);
            const response = await client.getEntries(queryOptions);
            setData(filterMissingAssets(response.items));
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [ids]);

    return { data, loading, error };
};
export default contentfulAssetsByIds;
