// @flow

import style from "./style.module.scss";

import React, { useState, useEffect, useRef } from "react";
import {
    SideDrawer as Drawer,
    Overlay,
    Typography,
    Icon,
} from "@2po-dgp/components";
import { createSalesPackBody, getSalespackObject } from "@utils/SalesPack";
import classnames from "classnames";
import { Footer as SPFooter, Content as SPContent } from "./SalesPackList";
import { Content as SVContent } from "./ShareView";
import { Header } from "./General";
import { Success } from "./SuccessView";
import { uploadToFileShare, deleteFromFileShare } from "@utils/Azure";
import { generateNormalizedFileName } from "@utils/NormalizePdfName";

import { STORAGE_SALESPACK_CONFIG } from "@utils/Constants";
import { saveLocalStorage } from "@utils/Storage";
import { STORAGE_SELECTED_ITEMS } from "@utils/Constants";
import { reorderAssetList } from "@utils/AssetList";
import {
    createDummyContentfulAssetFromFile,
    getDisclaimerAsset,
} from "@utils/Asset";
import { useApi } from "@hooks";
import makeEnum from "@utils/enum";
import * as clipboard from "clipboard-polyfill/text";
import axios from "axios";
import { downloader, timestamp, extension } from "@utils/file";

/**
 * SideDrawer
 * FIXME: Should be a container [TVD 26/10/2020]
 */
const SideDrawer = ({
    className,
    sideDrawerOpen,
    closeSideDrawer,
    onStatusChange,
    selectedItems,
    updateSelect,
    setReload,
    setHistoryOverviewOpen,
    setHelpViewOpen,
    showSaveTemplate,
}: *) => {
    const BASE_URL = process.env.GATSBY_PORTAL_BASE_URL || "";

    const VIEWS = makeEnum(["SALESPACK", "SHARE", "SUCCESS"]);
    const [activeView, setActiveView] = useState(VIEWS.SALESPACK);
    const [hasCopied, setHasCopied] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [containsPpt, setContainsPpt] = useState(false);
    const [salesPackBody, setSalesPackBody] = useState(null);
    const [salesPackId, setSalesPackId] = useState("");
    const [preferenceLanguage, setPreferenceLanguage] = useState("");
    const [activeItems, setActiveItems] = useState([]);
    const { apiLoading, apiData, apiError } = useApi({
        url: "/backend/salespacks",
        method: "post",
        data: salesPackBody,
    });
    const [uploadsInProgress, setUploadsInProgress] = useState([]);
    const [downloadLoadingPPT, setDownloadLoadingPPT] = useState(false);
    // refs needed for concurrent promises & state changes
    const uploadRef = useRef(uploadsInProgress);
    const selectedItemsRef = useRef([]);

    const enabled = selectedItems?.length > 0;
    const allAssetsSelected = selectedItems?.length === activeItems?.length;

    useEffect(() => {
        setActiveView(VIEWS.SALESPACK);
        setActiveItems([]);
        setHasCopied(false);
    }, [sideDrawerOpen]);

    useEffect(() => {
        onStatusChange(apiLoading);
    }, [apiLoading]);

    useEffect(() => {
        if (salesPackBody) {
            if (apiError) {
                console.log("### error occured creating salespack", apiError);
                setSalesPackBody(null);
            }
            if (!apiError && apiData) {
                generateSalespackPDF(apiData.salespackId);
                setContainsPpt(selectedItemsContainPpt());
                updateSelect([]);
                setSalesPackBody(null);
                setReload(true);
                setActiveView(VIEWS.SUCCESS);
                setSalesPackId(apiData?.salespackId);
                saveLocalStorage(
                    STORAGE_SALESPACK_CONFIG,
                    getSalespackObject(),
                );
            }
        }
    }, [apiData, apiError]);

    useEffect(() => {
        uploadRef.current = uploadsInProgress;
    }, [uploadsInProgress]);

    useEffect(() => {
        selectedItemsRef.current = selectedItems || [];
    }, [selectedItems]);

    useEffect(() => {
        // new uploadsInProgress always added last to the list
        const last = uploadsInProgress[uploadsInProgress.length - 1];
        if (!!last && last.loading && !last.response) {
            handleUpload(last);
        }
    }, [uploadsInProgress.length]);

    const generateSalespackPDF = salespackId => {
        axios({
            url: `/backend/salespacks/${salespackId}`,
            method: "post",
        });
    };

    const handleCreateSalesPack = value => {
        let implicitFiles = [];
        // Since API call is async, we need to wait for the response and continue afterwards.
        Promise.resolve(getDisclaimerAsset(value.preferenceLanguage)).then(
            disclaimer => {
                implicitFiles.push(...disclaimer);
                const salesPackFiles =
                    implicitFiles.length > 0
                        ? selectedItems.concat(implicitFiles)
                        : selectedItems;

                const apiBody = createSalesPackBody(
                    value.initials,
                    value.segment,
                    value.preferenceLanguage,
                    value.date,
                    salesPackFiles,
                );
                setSalesPackBody(apiBody);

                value?.preferenceLanguage &&
                    setPreferenceLanguage(value.preferenceLanguage);
            },
        );
    };

    const handleSelect = id => {
        let arr = activeItems.concat() || [];

        if (arr.some(assetId => assetId === id)) {
            arr = arr.filter(assetId => assetId !== id);
        } else arr.push(id);

        setActiveItems(arr);
    };

    const handleSelectAll = () => {
        const selectedItemIds = selectedItems.map(item => item?.sys?.id);
        activeItems?.length === selectedItems?.length
            ? setActiveItems([])
            : setActiveItems(selectedItemIds);
    };

    const handleReorder = direction => {
        const assets = reorderAssetList(direction, selectedItems, activeItems);
        updateAssetStorage(assets);
    };

    const handleCopy = () => {
        if (navigator && window) {
            clipboard.writeText(
                `${BASE_URL}/${preferenceLanguage}/${salesPackId}`,
            );
            setHasCopied(true);
        }
    };

    const handleDownload = async () => {
        setDownloadLoading(true);

        try {
            const {
                data: { salespackName },
            } = await axios.post(`/backend/salespacks/${salesPackId}`);

            await downloader(
                `/files/salespacks/${salespackName}?download=true`,
                timestamp(salespackName),
            );

            setDownloadLoading(false);
        } catch {
            setDownloadLoading(false);
        }
    };
    const handleDownloadSlides = async () => {
        setDownloadLoadingPPT(true);

        await downloader(
            `/backend/asset/downloadpptzip/${salesPackId}`,
            timestamp(`${salesPackId}.zip`),
        );

        setDownloadLoadingPPT(false);
    };

    const handleHistoryLink = () => {
        closeSideDrawer();
        setHistoryOverviewOpen();
        setHelpViewOpen();
    };

    const updateAssetStorage = assets => {
        saveLocalStorage(STORAGE_SELECTED_ITEMS, assets);
        updateSelect(assets);
    };

    const handleUpload = async (upload: *) => {
        try {
            const normalizedFileName = generateNormalizedFileName(
                upload.file.name,
            );
            const convertedFileName = await uploadToFileShare(
                upload.file,
                normalizedFileName,
            );
            const manualUploadItem = createDummyContentfulAssetFromFile(
                upload.file,
                convertedFileName,
            );
            updateSelect([...selectedItemsRef.current, manualUploadItem]);
        } catch (err) {
            console.log("#### error occurred uploading file", err);
        } finally {
            setUploadsInProgress(
                uploadRef.current.filter(item => item.id !== upload.id),
            );
        }
    };

    const handleDelete = () => {
        let arr = selectedItems.concat() || [];

        activeItems.forEach(activeItem => {
            const itemToDelete = arr.find(
                selectedItem => selectedItem?.sys?.id === activeItem,
            );

            if (
                itemToDelete?.fields?.category?.fields?.title ===
                    "Uploaded file" &&
                !itemToDelete.fromTemplate
            ) {
                removeFileFromServer(itemToDelete);
            } else
                arr = arr.filter(
                    selectedItem => selectedItem?.sys?.id !== activeItem,
                );
        });

        setActiveItems([]);
        updateAssetStorage(arr);
    };

    const removeFileFromServer = async (item: *) => {
        try {
            await deleteFromFileShare(item.fields.media.fields.file.fileName);
            const filtered = selectedItemsRef.current.filter(
                selected => selected.sys.id !== item.sys.id,
            );
            updateAssetStorage(filtered);
        } catch (err) {
            console.log("#### error deleting uploaded file", err);
        }
    };

    const selectForUpload = (file: File) => {
        const fileObj: any = {
            file,
            id: `${file.name}-${file.lastModified}`,
            loading: true,
        };
        setUploadsInProgress([...uploadsInProgress, fileObj]);
    };

    const selectedItemsContainPpt = () =>
        selectedItems.some(
            asset =>
                extension(asset?.fields.media.fields.file.fileName) === "pptx",
        );

    return (
        <div
            className={classnames(style.wrapper, className, {
                [style.open]: sideDrawerOpen,
            })}
        >
            <Drawer
                className={style.drawer}
                header={() => (
                    <div className={style.header}>
                        <div
                            className={style.backButton}
                            onClick={closeSideDrawer}
                        >
                            <Typography
                                type="panelHeaderText"
                                additionalClass={style.backButtonText}
                            >
                                <Icon
                                    iconName={"listCarret"}
                                    additionalClass={style.icon}
                                    size="sm"
                                />
                                {"Back to overview"}
                            </Typography>
                        </div>
                        {activeView === VIEWS.SALESPACK && (
                            <Header
                                title={"Your selection"}
                                enabled={enabled}
                                buttons={[
                                    {
                                        label: allAssetsSelected
                                            ? "Deselect all"
                                            : "Select all",
                                        icon: allAssetsSelected
                                            ? "circleOutline"
                                            : "select",
                                        activeColor: allAssetsSelected
                                            ? "dark"
                                            : "primary",
                                        onClick: () => handleSelectAll(),
                                    },
                                    {
                                        label: "Delete",
                                        icon: "delete",
                                        activeColor: "delete",
                                        active: activeItems?.length > 0,
                                        onClick: () => handleDelete(),
                                    },
                                ]}
                            />
                        )}
                        {activeView === VIEWS.SHARE && (
                            <Header
                                title={"Recipient details"}
                                enabled={enabled}
                                buttons={[
                                    {
                                        label: "Reset",
                                        icon: "refresh",
                                        onClick: () => {
                                            saveLocalStorage(
                                                STORAGE_SALESPACK_CONFIG,
                                                getSalespackObject(),
                                            );
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                )}
                content={() => (
                    <div className={style.content}>
                        {activeView === VIEWS.SALESPACK && (
                            <>
                                <SPContent
                                    items={selectedItems?.map(item => ({
                                        ...item?.fields,
                                        category: item?.fields?.category
                                            ? item?.fields?.category?.fields
                                                  ?.title
                                            : "",
                                        id: item?.sys?.id,
                                        selected: activeItems.some(
                                            id => id === item?.sys?.id,
                                        ),
                                    }))}
                                    onSelect={val => handleSelect(val)}
                                    uploads={uploadsInProgress.map(upload => ({
                                        ...upload,
                                        category: "Uploaded file",
                                        title: upload.file.name,
                                        selected: activeItems.some(
                                            id => id === upload.id,
                                        ),
                                    }))}
                                />
                            </>
                        )}
                        {activeView === VIEWS.SHARE && (
                            <SVContent
                                onSubmit={value => handleCreateSalesPack(value)}
                                loading={apiLoading}
                                selectedItems={selectedItems}
                            />
                        )}
                        {activeView === VIEWS.SUCCESS && (
                            <Success
                                hasCopied={hasCopied}
                                handleCopy={() => handleCopy()}
                                handleDownload={() => handleDownload()}
                                downloadLoading={downloadLoading}
                                downloadLoadingPPT={downloadLoadingPPT}
                                handleDownloadSlides={() =>
                                    handleDownloadSlides()
                                }
                                handleHistoryLink={() => handleHistoryLink()}
                                showDownloadSlidesButton={containsPpt}
                            />
                        )}
                    </div>
                )}
                footer={() =>
                    activeView === VIEWS.SALESPACK && (
                        <div className={style.footer}>
                            <SPFooter
                                button={{
                                    label: "Share selection",
                                    icon: "refresh",
                                }}
                                enableArrows={
                                    activeItems?.length > 0 &&
                                    !allAssetsSelected &&
                                    selectedItems?.length !== 1
                                }
                                moveUp={() => handleReorder("up")}
                                moveDown={() => handleReorder("down")}
                                shareSelection={() =>
                                    setActiveView(VIEWS.SHARE)
                                }
                                showSaveTemplate={showSaveTemplate}
                                uploadFile={file => selectForUpload(file)}
                                enabled={enabled}
                                popupCopy={getPopupCopy(
                                    allAssetsSelected,
                                    selectedItems?.length,
                                )}
                            />
                        </div>
                    )
                }
            />
            {sideDrawerOpen && (
                <Overlay
                    open={sideDrawerOpen}
                    onClick={() => {
                        closeSideDrawer();
                        setActiveView(VIEWS.SALESPACK);
                    }}
                />
            )}
        </div>
    );
};

export default SideDrawer;

const getPopupCopy = (allAssetsSelected, size) => {
    if (allAssetsSelected && size === 1) {
        return "You need multiple files to change the file order";
    }
    if (allAssetsSelected) {
        return "You can't move the order when all files are selected";
    }
    return "Make a selection to move a file up and down";
};
