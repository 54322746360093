import { useState, useEffect } from "react";
import getClient from "@utils/Client";
import { filterMissingAssets } from "@utils/Asset";
import { LANGUAGES } from "@utils/Constants";
// @flow

// use this to get assets of category <slug of category>
const contentfulCategoryAssets = ({ skip, limit, category, language }) => {
    const [categoryAssetsLoading, setCategoryAssetsLoading] = useState(false);
    const [categoryAssets, setCategoryAssets] = useState(null);
    const [categoryAssetsError, setCategoryAssetsError] = useState(null);

    const client = getClient();

    const fullLanguageName = LANGUAGES.filter(
        LANG => LANG.key.toLowerCase() === language.toLowerCase(),
    )[0].value;

    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        skip,
        limit,
        order: "fields.title",
    };

    const getAssets = async () => {
        try {
            setCategoryAssetsLoading(true);
            const response = await client.getEntries({
                ...queryOptions,
                "fields.category.sys.contentType.sys.id": "saleshubCategory",
                "fields.category.fields.slug[match]": category,
                "fields.lastProcessed[exists]": true,
                "fields.excludeFromSearch": false,
                "fields.language": fullLanguageName,
            });

            const { items, limit, skip, total } = response;

            setCategoryAssets({
                items: filterMissingAssets(items),
                limit,
                skip,
                total,
            });
            setCategoryAssetsLoading(false);
            setCategoryAssetsError(null);
        } catch (error) {
            setCategoryAssetsLoading(false);
            setCategoryAssetsError(error);
        }
    };

    useEffect(() => {
        getAssets();
    }, [category, skip, limit, language]);

    return { categoryAssetsLoading, categoryAssets, categoryAssetsError };
};
export default contentfulCategoryAssets;
