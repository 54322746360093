// @flow

import { useState, useEffect } from "react";
import axios from "axios";
import { appendSalesPackFilter } from "@utils/Filter";

// use this for fetching salespacks
// FIXME: Build upon the useApi Hook or at least use the api client creator
// [TVD 26/10/2020]
const SalesPacks = ({
    page,
    pageSize,
    filterValues,
    loadMore,
    reload,
}: Object) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    const queryOptions = {
        url: "/backend/salespacks",
        method: "get",
        params: {
            page,
            pageSize,
        },
    };

    const getSalesPacks = async () => {
        try {
            setLoading(true);
            const filters = appendSalesPackFilter(queryOptions, filterValues);
            const response = await axios(filters);
            const result =
                loadMore && data
                    ? {
                          ...data,
                          salespacks: data.salespacks.concat(
                              response.data.salespacks,
                          ),
                      }
                    : response.data;
            setData(result);
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        getSalesPacks();
    }, [page, pageSize, filterValues]);

    useEffect(() => {
        if (reload) {
            getSalesPacks();
        }
    }, [reload]);

    return { loading, data, error };
};
export default SalesPacks;
