const yup = require("yup");

export const schema = yup
    .object()
    .noUnknown(true)
    .shape({
        initials: yup
            .string()
            .required("Initials are required.")
            .test(
                "len",
                "Please choose 4 characters maximum to anonymize your contact.",
                val => val.length < 5,
            )
            .test("uppercase", "Uppercase characters only", val =>
                /^[A-Z]+$/.test(val),
            ),
        ["creation-date"]: yup.date().required(),
        ["appointment-date"]: yup
            .date()
            .typeError("Appointment date is required.")
            .required(),
        ["preference-language"]: yup
            .string()
            .required("Preference language is required."),
        segment: yup.string().required("Segment is required."),
        assets: yup.array().of(
            yup.object().shape({
                id: yup.string().required(),
                title: yup.string().required(),
                ["cf-object-id"]: yup.string().required(),
            }),
        ),
        confirmation: yup
            .bool()
            .oneOf(
                [true],
                "I confirm all data shared is compliant and anonymized",
            ),
    });
