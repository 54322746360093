// @flow

import style from "./style.module.scss";

import React, { useState, useRef } from "react";
import {
    Typography,
    Arrow,
    Popup,
    Button,
    Link,
    LinkV2,
} from "@2po-dgp/components";
import classnames from "classnames";

const Footer = ({
    button,
    enableArrows,
    popupCopy,
    moveUp,
    moveDown,
    shareSelection,
    showSaveTemplate,
    uploadFile,
    className,
    enabled,
}: *) => {
    const [popup, setPopup] = useState(false);
    const [validFileSize, setValidFileSize] = useState(true);
    const [validExtension, setValidExtension] = useState(true);
    const inputRef = useRef();
    const checkIfDisabled = () => {
        if (enabled) {
            showSaveTemplate();
        }
    };

    const handleFileChange = ev => {
        if (!ev.target.files || ev.target.files.length === 0) return;

        const MB = 1048576;
        const file = ev.target.files[0];

        if (file.size > 5 * MB) {
            setValidFileSize(false);
            return;
        }
        const fileExtension = file.name.split(".").pop();

        if (fileExtension !== "pdf") {
            setValidExtension(false);
            return;
        }
        setValidFileSize(true);
        uploadFile(file);
    };

    return (
        <div className={classnames(style.bottomRow, className)}>
            <div className={style.bottomRowActions}>
                <Arrow
                    type="up"
                    className={style.marginRight}
                    enabled={enableArrows}
                    onClick={() =>
                        enableArrows ? moveUp() : enabled && setPopup(true)
                    }
                />
                <Arrow
                    type="down"
                    enabled={enableArrows}
                    onClick={() =>
                        enableArrows ? moveDown() : enabled && setPopup(true)
                    }
                />
                {!enableArrows && popup && (
                    <Popup
                        icon="infoCircle"
                        copy={popupCopy}
                        onClose={() => setPopup(false)}
                    />
                )}
                <div className={style.upload}>
                    <label>
                        <Link
                            type="uploadLink"
                            onClick={e => {
                                e.preventDefault();
                                if (inputRef.current) {
                                    inputRef.current.click();
                                }
                            }}
                        >
                            {"+ Upload pdf file"}
                        </Link>
                        <input
                            type="file"
                            accept=".pdf"
                            name="pdf-upload"
                            className={style.hidden}
                            onChange={ev => handleFileChange(ev)}
                            ref={inputRef}
                        />
                    </label>
                    {!validExtension && (
                        <Typography
                            type="drawerDisclaimer"
                            additionalClass={style.drawerDisclaimer}
                        >
                            Wrong extension
                        </Typography>
                    )}
                    {validFileSize ? (
                        <Typography
                            type="drawerDisclaimer"
                            additionalClass={style.drawerDisclaimer}
                        >
                            {"Max 5MB/file"}
                        </Typography>
                    ) : (
                        <Typography
                            type="drawerDisclaimer"
                            additionalClass={classnames(
                                style.drawerDisclaimer,
                                style.error,
                            )}
                        >
                            Selected file is too big
                        </Typography>
                    )}
                </div>
            </div>
            <div onClick={enabled ? shareSelection : undefined}>
                <Button
                    icon={button.icon}
                    className={style.button}
                    disabled={!enabled}
                >
                    {button.label}
                </Button>
            </div>
            <div className={style.saveTemplate}>
                <Typography
                    type="drawerDisclaimer"
                    additionalClass={style.titleWithDivider}
                >
                    Or
                </Typography>
                <LinkV2
                    onClick={checkIfDisabled}
                    className={classnames(style.saveButton, {
                        [style.disabled]: !enabled,
                    })}
                    disabled={!enabled}
                >
                    Save salespack as a template
                </LinkV2>
            </div>
        </div>
    );
};

export default Footer;
