import { useState, useEffect } from "react";
import { appendAssetFilter } from "@utils/Filter";
import getClient from "@utils/Client";
import { LANGUAGES } from "@utils/Constants";
// @flow

// use this for searching assets
const contentfulSearch = ({ query, filterValues, language }) => {
    const [loading, setLoading] = useState(false);
    const [initialData, setInitialData] = useState(null);
    const [filteredValue, setFilteredValue] = useState(null);
    const [error, setError] = useState(null);

    const client = getClient();

    const fullLanguageName = LANGUAGES.filter(
        LANG => LANG.key.toLowerCase() === language.toLowerCase(),
    )[0].value;

    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        "fields.lastProcessed[exists]": true,
        "fields.language": fullLanguageName,
        order: "fields.title",
    };

    const filterByQuery = () => {
        const contains = (val, query) =>
            val?.toLowerCase().includes(query?.toLowerCase());

        const filteredByQuery = initialData?.items?.filter(asset => {
            return (
                contains(asset.fields.description, query) ||
                contains(asset.fields.title, query)
            );
        });

        setFilteredValue(filteredByQuery);
    };

    const fetchAssets = async () => {
        try {
            setLoading(true);
            const filters = appendAssetFilter(queryOptions, filterValues);
            const response = await client.getEntries(filters);
            const { items, total } = response;
            const filteredItems = items.filter(
                item => !item.fields.excludeFromSearch,
            );
            setInitialData({ items: filteredItems, total });
            setLoading(false);
            setError(null);
        } catch (error) {
            setLoading(false);
            setError(error);
        }
    };

    useEffect(() => {
        filterByQuery();
    }, [query, loading, language]);

    useEffect(() => {
        fetchAssets();
    }, [filterValues, language]);

    return {
        loading,
        data: {
            items: filteredValue,
            total: filteredValue?.length,
        },
        error,
        query,
    };
};
export default contentfulSearch;
