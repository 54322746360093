// @flow

import { useState, useEffect } from "react";
import axios from "axios";
import { appendSalesPackFilter } from "@utils/Filter";

// use this for fetching the total amount of salespacks
// FIXME: Build upon the useApi Hook or at least use the api client creator
// [TVD 26/10/2020]
// FIXME: Use a decent flow type annotation [TVD 26/10/2020]
const SalesPacksFilterCount = ({ filterValues }: Object) => {
    const [totalLoading, setTotalLoading] = useState(false);
    const [total, setTotal] = useState(null);

    const queryOptions = {
        url: "/backend/salespacks",
        method: "get",
        params: {
            page: 0,
            pageSize: 0,
        },
    };

    const getSalesPacks = async () => {
        try {
            setTotalLoading(true);
            const filters = appendSalesPackFilter(queryOptions, filterValues);
            const response = await axios(filters);
            setTotal(response?.data?.totalCount);
            setTotalLoading(false);
        } catch (error) {
            setTotalLoading(false);
        }
    };

    useEffect(() => {
        getSalesPacks();
    }, [filterValues]);

    return { totalLoading, total };
};
export default SalesPacksFilterCount;
