// @flow

import { Application } from "@containers";
import React from "react";
import { Helmet } from "react-helmet";

const HomePage = () => (
    <>
        <Helmet
            htmlAttributes={{
                lang: `en`,
            }}
        >
            <meta name="robots" content="noindex,nofollow" />
            <title>{"Saleshub"}</title>
        </Helmet>
        <Application />
    </>
);

export default HomePage;
