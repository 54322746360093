// @flow

import React from "react";
import { CustomCol } from "@components";

type Props = {
    children: *,
};

/**
 * GridCol
 */
const GridCol = ({ children }: Props) => (
    <CustomCol xs="12" md="6" lg="4" xl="3" xxl="2">
        {children}
    </CustomCol>
);

export default GridCol;
