// @flow

import style from "./style.module.scss";

import React from "react";
import {
    Typography,
    Icon,
    Pagination,
    FilterButton,
    Button,
} from "@2po-dgp/components";
import { SEGMENT_NAMES } from "@utils/Constants";
import { Row, Col } from "reactstrap";
import classnames from "classnames";

type Props = {
    amountOfPages?: number,
    currentPage?: number,
    setCurrentPage?: (*) => void,
    onLoadMore?: (*) => void,
    title?: string,
    displayReset?: boolean,
    clearQuery?: (*) => void,
    filters?: *,
    removeFilter?: *,
    className?: string,
    children?: *,
    filterIcon?: *,
};
/**
 * TilesGrid
 */
const TilesGrid = ({
    amountOfPages,
    currentPage,
    setCurrentPage,
    onLoadMore,
    title,
    displayReset,
    clearQuery,
    filters,
    removeFilter,
    className,
    children,
    filterIcon,
}: Props) => {
    // Mapping for proper segment names
    const displayedFilters = filters?.map(filter => {
        if (Object.keys(SEGMENT_NAMES).indexOf(filter) > -1) {
            const oldValue = filter;
            return {
                label: SEGMENT_NAMES[oldValue],
                value: oldValue,
            };
        }
        return filter;
    });

    return (
        <div className={classnames(className, style.tilesGrid)}>
            {title && (
                <Row>
                    <Col
                        className={classnames(style.header, {
                            [style.filtersEnabled]:
                                filters && filters.length > 0,
                        })}
                    >
                        <div className={style.upperHeader}>
                            <Typography
                                type="panelHeader"
                                color={"primary"}
                                additionalClass={style.title}
                            >
                                {title}
                            </Typography>
                            {displayReset && (clearQuery || removeFilter) && (
                                <div
                                    className={style.clear}
                                    onClick={() => !!clearQuery && clearQuery()}
                                >
                                    <Typography
                                        type="panelHeaderText"
                                        additionalClass={style.headerLabel}
                                    >
                                        {displayedFilters &&
                                        displayedFilters.length > 0
                                            ? "Clear all filters"
                                            : "Clear search result"}
                                    </Typography>
                                    <Icon
                                        iconName="refresh"
                                        variant="neutral"
                                    />
                                </div>
                            )}
                        </div>
                        <div className={style.lowerHeader}>
                            {displayedFilters && displayedFilters.length > 0 && (
                                <div className={style.filters}>
                                    {displayedFilters?.map((value, index) => (
                                        <FilterButton
                                            key={index}
                                            icon="smallCross"
                                            onClick={() =>
                                                !!removeFilter &&
                                                removeFilter(
                                                    value.value
                                                        ? value.value
                                                        : value,
                                                )
                                            }
                                        >
                                            {value.label ? value.label : value}
                                        </FilterButton>
                                    ))}
                                </div>
                            )}
                            {filterIcon && filterIcon()}
                        </div>
                    </Col>
                </Row>
            )}
            <Row className={style.grid}>{children}</Row>
            {amountOfPages &&
                setCurrentPage &&
                currentPage &&
                onLoadMore &&
                amountOfPages > 1 && (
                    <Row>
                        <Col
                            xs="12"
                            className={classnames(
                                style.pagination,
                                "d-none",
                                "d-sm-none",
                                "d-md-block",
                            )}
                        >
                            <Pagination
                                nrOfPages={amountOfPages}
                                activePageNumber={currentPage}
                                onPageChange={number => {
                                    setCurrentPage(number);
                                }}
                            />
                        </Col>
                        {currentPage < amountOfPages && (
                            <Col
                                xs="12"
                                className={classnames(
                                    style.pagination,
                                    "d-md-none",
                                )}
                            >
                                <Button
                                    onClick={() => onLoadMore(currentPage + 1)}
                                    cover
                                >
                                    Load more
                                </Button>
                            </Col>
                        )}
                    </Row>
                )}
        </div>
    );
};

export default TilesGrid;
