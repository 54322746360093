// @flow
import React, { useEffect, useState, Fragment } from "react";
import { TilesGrid, AssetTile, GridCol } from "@components";
import { Loading, Panel } from "@2po-dgp/components";
import { useContentfulSearch } from "@hooks";
import style from "./style.module.scss";

const CategoryDrawer = ({
    category,
    selectedItems,
    clearQuery,
    setPreviewModalData,
    removeAssetFromSelection,
    addAssetToSelection,
}: *) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Panel
                category={`${category.fields.title} (${category?.children?.length})`}
                arrowLabel="Minimize"
                defaultState={false}
                open={open}
                onClick={setOpen}
                smallHeading={true}
            >
                <TilesGrid clearQuery={clearQuery}>
                    {category.children.map((asset, index) => {
                        return (
                            <GridCol key={index}>
                                <AssetTile
                                    asset={asset}
                                    removeAsset={val =>
                                        removeAssetFromSelection(val)
                                    }
                                    addAsset={val => addAssetToSelection(val)}
                                    selected={selectedItems?.find(
                                        selectedAsset =>
                                            selectedAsset?.sys?.id ===
                                            asset?.sys?.id,
                                    )}
                                    setPreviewModalData={val =>
                                        setPreviewModalData(val)
                                    }
                                />
                            </GridCol>
                        );
                    })}
                </TilesGrid>
            </Panel>
        </>
    );
};

const Search = ({
    query,
    filterValues,
    selectedItems,
    clearQuery,
    removeFilter,
    setPreviewModalData,
    removeAssetFromSelection,
    addAssetToSelection,
    chosenLanguage,
}: *) => {
    const [categories, setCategories] = useState([]);

    const { loading, data, error } = useContentfulSearch({
        content_type: "saleshubAsset",
        query,
        filterValues,
        language: chosenLanguage,
    });

    useEffect(() => {
        const cat = [];

        data?.items?.forEach(item => {
            if (!cat.includes(item.fields.category)) {
                cat.push(item.fields.category);
            }

            const currentFoundCategories = cat.filter(e => {
                return e.fields.slug === item.fields.category.fields.slug;
            });

            const currentCategory = currentFoundCategories
                ? currentFoundCategories[0]
                : null;

            if (currentCategory !== null) {
                if (currentCategory.children) {
                    if (!currentCategory.children.includes(item)) {
                        currentCategory.children.push(item);
                    }
                } else {
                    currentCategory.children = [item];
                }
            }
        });

        // some results get added without being in the results, so we filter them out
        cat.forEach(c => {
            c.children = c.children.filter(item => data.items.includes(item));
        });

        setCategories(
            cat.sort((a, b) =>
                a.fields.slug < b.fields.slug
                    ? -1
                    : a.fields.slug > b.fields.slug
                    ? 1
                    : 0,
            ),
        );
    }, [data?.items?.length]);

    if (!!(query || filterValues.length > 0) && data?.items) {
        return (
            <Fragment>
                <TilesGrid
                    className={style.searchGrid}
                    clearQuery={clearQuery}
                    title={
                        filterValues.length > 0 && !query
                            ? `Filter results (${data?.total})`
                            : `Search results (${data?.total})`
                    }
                    filters={filterValues.map(filter => filter.value)}
                    removeFilter={val => removeFilter(val)}
                />
                <div className={style.panelCol}>
                    {categories.map((cat, index) => {
                        return (
                            <CategoryDrawer
                                category={cat}
                                key={index}
                                filterValues={filterValues}
                                query={query}
                                selectedItems={selectedItems}
                                clearQuery={clearQuery}
                                removeFilter={removeFilter}
                                setPreviewModalData={setPreviewModalData}
                                removeAssetFromSelection={
                                    removeAssetFromSelection
                                }
                                addAssetToSelection={addAssetToSelection}
                            />
                        );
                    })}
                </div>
                {loading && (
                    <Loading loading={loading} error={error?.message} />
                )}
            </Fragment>
        );
    }

    return "";
};

export default Search;
