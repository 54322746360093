// @flow

import React from "react";
import { Col } from "reactstrap";

/**
 * CustomCol
 */
/* Currently the only way to apply the custom bootstrap grid sizes https://reactstrap.github.io/components/layout/ */
const CustomCol = (props: *) => (
    <Col widths={["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"]} {...props} />
);

export default CustomCol;
