// @flow
import makeEnum from "@utils/enum";
//import moment from "moment";

export const FILTERS = makeEnum([
    "initials",
    "languages",
    "segments",
    "startDate",
    "endDate",
    "categories",
    "archive",
]);

const ALL = "All";

export const appendAssetFilter = (query: *, filters: *) => {
    query["fields.segments[in]"] = undefined;

    if (filters?.length > 0) {
        const languagesString = getFilterString(FILTERS.languages, filters);
        const segmentsString = getFilterString(FILTERS.segments, filters);
        const categoriesString = getFilterString(FILTERS.categories, filters);

        if (languagesString) {
            query["fields.language[in]"] = languagesString
                .toLowerCase()
                .replace("en", "English")
                .replace("nl", "Nederlands")
                .replace("fr", "Français");
        }
        if (segmentsString) {
            query["fields.segments[in]"] = `${segmentsString},${ALL}`;
        }
        if (categoriesString) {
            query["fields.category.sys.contentType.sys.id"] =
                "saleshubCategory";
            query["fields.category.fields.title[in]"] = categoriesString;
        }
    }

    return query;
};

export const appendSalesPackFilter = (query: *, filters: *) => {
    query["fields.segments[in]"] = undefined;

    if (filters?.length > 0) {
        const invisibleString = getFilterString(FILTERS.archive, filters);
        const languagesString = getFilterString(FILTERS.languages, filters);
        const segmentsString = getFilterString(FILTERS.segments, filters);
        const startDateString = getFilterString(FILTERS.startDate, filters);
        const endDateString = getFilterString(FILTERS.endDate, filters);
        const initialsString = getFilterString(FILTERS.initials, filters);

        if (invisibleString) {
            query.params["invisible"] = true;
        }
        if (languagesString) {
            query.params["peferencelanguage"] = languagesString.toLowerCase();
        }
        if (segmentsString) {
            query.params["segment"] = segmentsString;
        }
        if (startDateString && endDateString) {
            query.params["appointmentdatefrom"] = new Date(startDateString);
            query.params["appointmentdateto"] = new Date(endDateString);
        }
        if (initialsString) {
            query.params["initials"] = initialsString;
        }
    }

    return query;
};

const getFilterString = (filterVal, filterValues) =>
    filterValues
        ?.filter(val => val.key.toLowerCase() === filterVal.toLowerCase())
        .map(val => val.value)
        .join(",");
