import { useState, useEffect } from "react";
import getClient from "@utils/Client";
// @flow

// use this to get all categories inside contentful
const contentfulCategories = () => {
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState(null);
    const [categoriesError, setCategoriesError] = useState(null);

    const client = getClient();

    const queryOptions = {
        content_type: "saleshubCategory",
        locale: "en",
        order: "fields.title",
    };

    const getAssets = async () => {
        try {
            setCategoriesLoading(true);
            const response = await client.getEntries(queryOptions);
            const { items, limit, skip, total } = response;
            setCategories({ items, limit, skip, total });
            setCategoriesLoading(false);
            setCategoriesError(null);
        } catch (error) {
            setCategoriesLoading(false);
            setCategoriesError(error);
        }
    };

    useEffect(() => {
        getAssets();
    }, []);

    return { categoriesLoading, categories, categoriesError };
};
export default contentfulCategories;
