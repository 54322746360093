// @flow

export const reorderAssetList = (
    direction: string,
    selectedItems: *,
    activeItems: *,
) => {
    const assets = selectedItems.concat();

    const firstItemIndex = activeItems.reduce((firstIndex, curr, idx) => {
        const nextIndex = assets.findIndex(asset => asset.sys.id === curr);
        if (idx === 0) return nextIndex;
        return nextIndex < firstIndex ? nextIndex : firstIndex;
    }, 0);

    const isSelected = (asset: *, activeItems: Array<*>) =>
        activeItems.some(id => id === asset.sys.id);
    const notSelectedAssets = assets.filter(
        asset => !isSelected(asset, activeItems),
    );
    const selectedAssets = assets.filter(asset =>
        isSelected(asset, activeItems),
    );

    let targetIndex;
    if (direction === "up") {
        targetIndex = firstItemIndex === 0 ? 0 : firstItemIndex - 1;
    } else {
        targetIndex =
            firstItemIndex + 1 <= notSelectedAssets.length
                ? firstItemIndex + 1
                : notSelectedAssets.length;
    }
    notSelectedAssets.splice(targetIndex, 0, ...selectedAssets);
    return notSelectedAssets;
};
