// @flow

import style from "./style.module.scss";

import React from "react";
import { Typography, Icon } from "@2po-dgp/components";
import classnames from "classnames";

const Header = ({ title, buttons, className, enabled }: *) => (
    <div
        className={classnames(style.topRow, className, {
            [style.inline]: buttons?.length < 2,
        })}
    >
        <Typography type="drawerTitle" additionalClass={style.drawerTitle}>
            {title}
        </Typography>
        <div className={style.actions}>
            {buttons.map((button, index) => (
                <div key={index} onClick={enabled ? button.onClick : undefined}>
                    <Typography
                        type="drawerActions"
                        additionalClass={classnames(
                            style.label,
                            style[button.activeColor],
                            { [style.active]: button.active },
                        )}
                    >
                        {button.label}
                    </Typography>
                    <Icon iconName={button.icon} variant="neutral" />
                </div>
            ))}
        </div>
    </div>
);

export default Header;
