// @flow

import style from "./style.module.scss";

import React, { useState } from "react";
import { PreviewTile, Overlay } from "@2po-dgp/components";
import { PDFPreview } from "@components";
import { mapAssetInfo } from "@utils/Asset";
import { generateNormalizedFileName } from "@utils/NormalizePdfName";
import { downloader, extension } from "@utils/file";

/**
 * PreviewModal
 */
const PreviewModal = ({
    previewModalData,
    setPreviewModalData,
    selectedItems,
    removeAsset,
    addAsset,
    className,
}: *) => {
    const [downloadLoading, setDownloadLoading] = useState(false);

    const previewSelected =
        selectedItems &&
        selectedItems.find(selectedAsset => {
            return selectedAsset?.sys?.id === previewModalData?.sys?.id;
        });

    const previewModalFileExtension =
        previewModalData &&
        extension(previewModalData?.fields.media.fields.file.fileName);

    const handleDownload = async (asset, extension) => {
        setDownloadLoading(true);

        await downloader(
            `/backend/asset/downloadSingle/${asset.sys.id}`,
            `${generateNormalizedFileName(asset.fields.title)}.${extension}`,
        );

        setDownloadLoading(false);
    };

    return previewModalData ? (
        <div className={className}>
            <PreviewTile
                className={style.previewModal}
                title={previewModalData.fields?.title}
                copy={previewModalData.fields?.description}
                buttons={[
                    {
                        label: previewSelected
                            ? "Remove from your selection"
                            : "Add to your selection",
                        icon: previewSelected ? "removeBasket" : "bigBasket",
                        active: previewSelected,
                        onClick: () =>
                            previewSelected
                                ? removeAsset(previewModalData)
                                : addAsset(previewModalData),
                    },
                    {
                        label: "Download slides",
                        icon: "download",
                        onClick: () =>
                            handleDownload(
                                previewModalData,
                                previewModalFileExtension,
                            ),
                        loading: downloadLoading,
                    },
                ]}
                assetInfo={mapAssetInfo(previewModalData)}
                pdfPreview={() => (
                    <PDFPreview
                        file={`files/documents/${generateNormalizedFileName(
                            previewModalData.fields.title,
                            previewModalData.fields.language,
                        )}.pdf`}
                    />
                )}
                onClose={() => setPreviewModalData(undefined)}
            />
            <Overlay
                onClick={() => setPreviewModalData(undefined)}
                className={style.overlay}
            />
        </div>
    ) : (
        ""
    );
};
export default PreviewModal;
