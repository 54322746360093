// @flow

import style from "./style.module.scss";

import React, { useEffect } from "react";
import { Filter as _Filter, Overlay } from "@2po-dgp/components";
import classnames from "classnames";

/**
 * Filter
 */
const Filter = ({
    filters,
    open,
    setOpen,
    applyFilter,
    initialFilters,
    selectedFilters,
    setSelectedFilters,
    loading,
    inputSearchField,
    datePicker,
    datePickerLabel,
    bottomText,
    className,
    title,
}: *) => {
    useEffect(() => {
        if (!open) {
            setSelectedFilters(initialFilters);
        }
    }, [open]);

    return (
        <>
            <_Filter
                values={selectedFilters}
                title={title || "Filter"}
                icon={"bigCross"}
                filters={filters}
                loading={loading}
                bottomText={bottomText}
                button={{
                    label: "Show the results",
                    onClick: () => {
                        applyFilter(selectedFilters);
                        setOpen(false);
                    },
                    icon: "arrowRight",
                    cover: false,
                }}
                onClose={() => setOpen(false)}
                onSelect={val => setSelectedFilters(val)}
                className={classnames(className, style.filter, {
                    [style.open]: open,
                })}
                inputSearchField={inputSearchField}
                datePicker={datePicker}
                datePickerLabel={datePickerLabel}
            />
            {open && (
                <Overlay
                    onClick={() => setOpen(false)}
                    className={style.overlay}
                />
            )}
        </>
    );
};

export default Filter;
