// @flow

import React from "react";
// import { mapAssetInfo } from "@utils/Asset";
import { AssetTile as Tile } from "@2po-dgp/components";
import classnames from "classnames";

/**
 * AssetTile
 */
const AssetTile = ({
    asset,
    selected,
    setPreviewModalData,
    removeAsset,
    addAsset,
    showPopup,
    handlePopupDismiss,
    className,
}: *) => {
    return asset ? (
        <Tile
            className={classnames(className)}
            category={
                asset?.fields?.category?.fields?.title
                    ? asset.fields.category.fields.title
                    : ""
            }
            title={asset.fields?.title}
            copy={asset.fields?.description}
            buttonA={{
                className: "d-none d-md-block",
                icon: "quickView",
                onClick: () => setPreviewModalData(asset),
            }}
            buttonB={{
                className: "ml-auto",
                label: selected ? "Remove" : "Add",
                icon: selected ? "removeBasket" : "bigBasket",
                active: selected,
                onClick: () =>
                    selected ? removeAsset(asset) : addAsset(asset),
            }}
            // assetInfo={mapAssetInfo(asset)}
            // To hide it for now, we just pass on an empty array
            assetInfo={[]}
            showPopup={showPopup}
            popupCopy={showPopup ? popupCopy : undefined}
            handlePopupDismiss={handlePopupDismiss}
        />
    ) : (
        ""
    );
};

export default AssetTile;

const popupCopy =
    "You can start by adding files into your selection by clicking this button";
