// @flow

const contentful = require("contentful");

const getClient = () =>
    contentful.createClient({
        space: process.env.GATSBY_CF_SPACE_ID,
        environment: process.env.GATSBY_CF_ENVIRONMENT,
        accessToken: process.env.GATSBY_CF_TOKEN,
    });

export default getClient;
