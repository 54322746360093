// @flow
import { LANGUAGES } from "@utils/Constants";
import moment from "moment";
import { getFileSizeString } from "@utils/FileSize";
import getClient from "@utils/Client";

export const mapAssetInfo = (asset: *) => [
    {
        label: moment(asset.sys.updatedAt).format("DD.MM.YYYY"),
        icon: "calendar",
    },
    {
        label: LANGUAGES.find(
            lang =>
                lang.value.toLowerCase() ===
                asset.fields.language.toLowerCase(),
        )?.key.toUpperCase(),
        icon: "language",
    },
    {
        label: getFileSizeString(asset.fields.media.fields.file.details.size),
        icon: "size",
    },
];

export const generateUploadedFileStructure = (
    uploaded: *,
    fileName: string,
    isUploadedFileFromTemplate: boolean = false,
) => {
    const currentDate = new Date();
    return {
        sys: {
            id: isUploadedFileFromTemplate
                ? uploaded["cf-object-id"]
                : `unknown_${moment(currentDate).format("DDMMYYYYHHmmss")}`,
            updatedAt: isUploadedFileFromTemplate
                ? uploaded["upload-date"]
                : currentDate,
            locale: uploaded.language,
        },
        fields: {
            category: {
                fields: {
                    slug: "uploadedfile",
                    title: "Uploaded file",
                },
            },
            language: "English",
            media: {
                sys: {
                    id: "unknown",
                    updatedAt: isUploadedFileFromTemplate
                        ? uploaded["upload-date"]
                        : currentDate,
                },
                fields: {
                    title: isUploadedFileFromTemplate
                        ? uploaded.title
                        : generateTitle(uploaded.name),
                    file: {
                        details: {
                            size: isUploadedFileFromTemplate
                                ? uploaded["file-size"]
                                : uploaded.size,
                        },
                        fileName: isUploadedFileFromTemplate
                            ? uploaded["file-name"]
                            : fileName,
                    },
                },
            },
            title: isUploadedFileFromTemplate
                ? uploaded.title
                : generateTitle(uploaded.name),
        },
        fromTemplate: isUploadedFileFromTemplate,
    };
};

export const createDummyContentfulAssetFromFile = (file: *, fileName: *) => {
    return generateUploadedFileStructure(file, fileName);
};

const generateTitle = (fileName: *) => {
    let currentFileName = fileName.split("_").join(" ");
    currentFileName = currentFileName.split("-").join(" ");
    currentFileName = currentFileName.split(".")[0];
    return currentFileName;
};

export const filterMissingAssets = (assets: *) => {
    return assets.filter(asset => asset?.fields?.media?.fields?.file?.url);
};

export const getDisclaimerAsset = async (language?: string) => {
    const usedLanguage = LANGUAGES.filter(lang => lang.key === language)[0];
    const client = getClient();
    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        limit: 1,
        order: "fields.title",
    };
    const response = await client.getEntries({
        ...queryOptions,
        "metadata.tags.sys.id[all]": "saleshubDisclaimer",
        "fields.language[match]": usedLanguage?.value,
    });

    console.debug("response of disclaimer asset", response.items, response);

    return response.items;
};

export const getAssets = async (ids: *) => {
    const client = getClient();
    const queryOptions = {
        content_type: "saleshubAsset",
        locale: "en",
        "sys.id[in]": ids.join(","),
    };
    const response = await client.getEntries({
        ...queryOptions,
    });
    return response.items;
};
