// @flow

export const loadLocalStorage = (id: *) => {
    try {
        const serializedState = localStorage.getItem(id);
        if (serializedState) {
            const state = JSON.parse(serializedState);
            if (new Date().getTime() > state.expiry) {
                localStorage.removeItem(id);
                //return Array.isArray(state?.value) ? [] : null;
                return null;
            }
            return state.value;
        }
        return null;
    } catch (err) {
        return null;
    }
};

export const saveLocalStorage = (id: *, state: *) => {
    try {
        const stateWithDate = {
            value: state,
            expiry: new Date().getTime() + 86400000,
        };
        const serializedState = JSON.stringify(stateWithDate);
        serializedState && localStorage.setItem(id, serializedState);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
    }
};
