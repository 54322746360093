const STORAGE_SELECTED_ITEMS = "selectedAssets";
const STORAGE_SALESPACK_CONFIG = "salespackConfig";
const POPUP_DISMISSED = "popupDismissed";
const INFO_POPUP_DISMISSED = "infoPopupDismissed";
const DRAWER_OPENED = "drawerOpened";

const ASSET_GRID_SIZE = 12;

const SEGMENTS = [
    "Young Gen",
    "Gen X",
    "Babyboomers",
    "FBO",
    "Independent Professionals",
    "Professional Wealth",
];

const SEGMENT_NAMES = {
    "Young Gen": "Private - YAT",
    "Gen X": "Private - Gen X (40y-65y)",
    Babyboomers: "Private - Babyboomers (+65y)",
    FBO: "FBO",
    "Independent Professionals": "Independent Professionals",
    "Professional Wealth": "Professional Wealth",
};

const LANGUAGES = [
    { key: "en", value: "English" },
    { key: "nl", value: "Nederlands" },
    { key: "fr", value: "Français" },
];

const SEGMENT_DROPDOWN_DEFAULT = "Select contact's segment";
const LANGUAGE_DROPDOWN_DEFAULT = "Select contact's language";
const DATEFIELD_DEFAULT = "Appointment (dd/mm/yyyy)";

module.exports.STORAGE_SELECTED_ITEMS = STORAGE_SELECTED_ITEMS;
module.exports.STORAGE_SALESPACK_CONFIG = STORAGE_SALESPACK_CONFIG;
module.exports.POPUP_DISMISSED = POPUP_DISMISSED;
module.exports.INFO_POPUP_DISMISSED = INFO_POPUP_DISMISSED;
module.exports.DRAWER_OPENED = DRAWER_OPENED;

module.exports.ASSET_GRID_SIZE = ASSET_GRID_SIZE;

module.exports.SEGMENTS = SEGMENTS;
module.exports.SEGMENT_NAMES = SEGMENT_NAMES;
module.exports.LANGUAGES = LANGUAGES;

module.exports.SEGMENT_DROPDOWN_DEFAULT = SEGMENT_DROPDOWN_DEFAULT;
module.exports.LANGUAGE_DROPDOWN_DEFAULT = LANGUAGE_DROPDOWN_DEFAULT;
module.exports.DATEFIELD_DEFAULT = DATEFIELD_DEFAULT;
