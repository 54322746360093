// @flow

import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import style from "./style.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import classnames from "classnames";
import enGb from "date-fns/locale/en-GB";
import { registerLocale } from "react-datepicker";
registerLocale("en-gb", enGb);

type Props = {
    selected: *,
    onChange: (*) => void,
    className?: string,
    error?: *,
    placeholderText?: string,
    startDate?: *,
    endDate?: *,
    ref?: *,
    selectsStart?: boolean,
    selectsEnd?: boolean,
    onCalendarClose?: *,
};

const DateField = (props: Props, ref) => {
    return (
        <div className={classnames(props.className, style.wrapper)}>
            <ReactDatePicker
                {...props}
                dateFormat="dd/MM/yyyy"
                className={classnames(style.dateField, {
                    [style.coloredBorder]: props.error,
                })}
                ref={ref}
                locale="en-gb"
                popperModifiers={{
                    preventOverflow: {
                        enabled: true,
                    },
                }}
            />
            {props.error && <span className={style.error}>{props.error}</span>}
        </div>
    );
};

export default forwardRef<*, *>(DateField);
