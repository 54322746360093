// @flow

import { useState, useEffect, useCallback } from "react";
import { create as getApiClient } from "../api";

/**
 * Hook to use api client
 */
const useApi = (axiosOptions: Object) => {
    const [apiLoading, setApiLoading] = useState(false);
    const [apiData, setApiData] = useState(null);
    const [apiError, setApiError] = useState(null);

    const makeRequest = async options => {
        setApiLoading(true);
        const response = await getApiClient().request(options);
        setApiData(response.data);
        setApiLoading(false);
        setApiError(null);
    };

    const refetch = useCallback(async () => {
        try {
            if (axiosOptions.url && axiosOptions.method) {
                if (
                    (axiosOptions.method === "post" ||
                        axiosOptions.method === "put") &&
                    axiosOptions.data
                ) {
                    await makeRequest(axiosOptions);
                }

                if (axiosOptions.method === "get") {
                    await makeRequest(axiosOptions);
                }
            }
        } catch (error) {
            setApiLoading(false);
            setApiError(error);
        }
    }, [axiosOptions.method, axiosOptions.data]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    // FIXME: Change naming to loading, data, error [TVD 26/10/2020]
    return { apiLoading, apiData, apiError, refetch };
};
export default useApi;
