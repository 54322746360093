// @flow

import style from "./style.module.scss";

import React from "react";
import { Button, Typography, Link } from "@2po-dgp/components";
import classnames from "classnames";

const CTAs = ({ buttons, copy, link, className }: *) => {
    return (
        <div className={classnames(style.content, className)}>
            <div className={style.buttons}>
                {buttons.map((button, index) => (
                    <Button
                        key={index}
                        icon={button.icon}
                        className={classnames(style.button, {
                            [style.active]: button.active,
                        })}
                        cover
                        onClick={() => button.onClick()}
                        loading={button.loading}
                        iconVariant="neutral"
                    >
                        {button.label}
                    </Button>
                ))}
            </div>
            <div className={style.copy}>
                <Typography type="successCopy">{copy}</Typography>
                <Link
                    type="profileLink"
                    onClick={link.onClick}
                    className={style.link}
                >
                    {link.label}
                </Link>
            </div>
        </div>
    );
};

export default CTAs;
